import ApiService from '@/assets/js/api.service';

// ACTION
export const ACT_GET_BOARD_LIST = 'actGetBoardList';
export const ACT_DOWNLOAD_BOARD_ATCH = 'actDownloadBoardAtch';
export const ACT_DOWNLOAD_COM_ATCH = 'actDownloadComAtch';

// LMS_LRN_BOARD
export const ACT_GET_LRN_BOARD = 'getLrnBoard';
export const ACT_GET_LRN_BOARD_LIST = 'getLrnBoardList';
export const ACT_INSERT_LRN_BOARD = 'insertLrnBoard';
export const ACT_UPDATE_LRN_BOARD = 'updateLrnBoard';
export const ACT_UPDATE_LRN_BOARD_SUMGO = 'updateLrnBoardSumgo';
export const ACT_DELETE_LRN_BOARD = 'deleteLrnBoard';

// LMS_BOARD_CATE
export const ACT_GET_LRN_BOARD_CATE_LIST = 'getLrnBoardCateList';

export const ACT_GET_LRN_REPORT = 'getLrnReport';
export const ACT_UPDATE_LRN_REPORT = 'updateLrnReport';

export const ACT_GET_LRN_BOARD_CMNT_LIST = 'getLrnBoardCmntList';
export const ACT_INSERT_LRN_BOARD_CMNT = 'insertLrnBoardCmnt';
export const ACT_UPDATE_LRN_BOARD_CMNT = 'updateLrnBoardCmnt';
export const ACT_DELETE_LRN_BOARD_CMNT = 'deleteLrnBoardCmnt';
export const ACT_UPLOAD_LEARN_BOARD_IMAGE = 'uploadLearnBoardImage';
export const ACT_UPLOAD_LEARN_BOARD_ATCH = 'uploadLearnBoardAtch';

export const ACT_GET_LEARN_BOARD_WITH_COMMENT = 'getLearnBoardWithComment';
export const ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST = 'getLearnBoardWithCommentList';
export const ACT_GET_SOCS_EDITOR_UPLOAD_OCR_MASKING = 'getSocsEditorUploadOcrMasking';

export const ACT_INSERT_LRN_BOARD_LK = 'insertLrnBoardLk';
export const ACT_DELETE_LRN_BOARD_LK = 'deleteLrnBoardLk';
export const ACT_GET_LRN_BOARD_STATUS = 'getLrnBoardStatus';
export const ACT_GET_LRN_BOARD_EVENT_STATUS = 'getLrnBoardEventStatus';

export const ACT_GET_GROUP_LRN_BOARD_LIST = 'getGroupLrnBoardList';
export const ACT_GET_GROUP_LRN_BOARD = 'getGroupLrnBoard';
export const ACT_GET_GROUP_LRN_BOARD_MNG = 'getGroupLrnBoardMng';
export const ACT_UPDATE_GROUP_LRN_BOARD_MNG = 'updateGroupLrnBoardMng';
export const ACT_GET_CHECK_PWD_GROUP_LRN_BOARD_MNG = 'getCheckPwdGroupLrnBoardMng';
export const ACT_UPDATE_GROUP_SELF_INTRO = 'updateGroupSelfIntro';
export const ACT_INSERT_GROUP_LRN_BOARD = 'insertGroupLrnBoard';
export const ACT_UPDATE_GROUP_LRN_BOARD = 'updateGroupLrnBoard';
export const ACT_DELETE_GROUP_LRN_BOARD = 'deleteGroupLrnBoard';

export const ACT_DELETE_GROUP_LRN_TRGT_DTL = 'deleteGroupLrnTrgtDtl';
export const ACT_UPDATE_GROUP_LEADER = 'updateGroupLeader';

export const ACT_GET_BOARD_MNG = 'getBoardMng';
export const ACT_UPDATE_BOARD_MNG = 'updateBoardMng';
export const ACT_GET_GROUP_BOARD_TRGT = 'getGroupBoardTrgt';

export const ACT_GET_GROUP_LRN_BOARD_STATUS = 'getGroupLrnBoardStatus';
export const ACT_GET_GROUP_LRN_BOARD_STATUS_LIST = 'getGroupLrnBoardStatusList';
export const ACT_GET_GROUP_LRN_ATTN_STATUS_LIST = 'getGroupLrnAttnStatusList';
export const ACT_UPDATE_GROUP_LEARN_BOARD_CN = 'updateGroupLearnBoardCn';
export const ACT_GET_GROUP_LRN_MTQ_ACPT_LIST = 'getGroupLrnMtqAcptList';
export const ACT_GET_LRN_BOARD_FILE_ATCH_LIST = 'actGetLrnBoardFileAtchList'

export const ACT_GET_GROUP_LRN_BOARD_HRDCLOUD = 'getGroupLrnBoardHrdcloud';

export const ACT_UPDATE_PROG_LOG_LRN_STATUS = 'getUpdateProgLogLrnStatus';

export const MUT_SET_GROUP_DIST_CRSE_SN = 'setGroupDistCrseSn';
export const MUT_SET_GROUP_DIST_PWDTOKEN = 'setGroupDistPwdToken';

const state = {
    distCrseSn: 0,
    pwdToken: null
};

const mutations = {
    [MUT_SET_GROUP_DIST_CRSE_SN](state, distCrseSn){
        state.distCrseSn = distCrseSn;
    },
    [MUT_SET_GROUP_DIST_PWDTOKEN](state, pwdToken){
        state.pwdToken = pwdToken;
    }
};

const actions = {
    [ACT_GET_BOARD_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/boards', params).then(response => resolve(response))
        });
    },
    [ACT_DOWNLOAD_BOARD_ATCH](context, {lrnPostFileAtchSn,fileName}){
        ApiService.download('/v1/app/boards/atchs', lrnPostFileAtchSn, null, fileName);
    },
    [ACT_DOWNLOAD_COM_ATCH](context, {secuKey, fileName}){
        ApiService.download('/v1/app/file/atch', secuKey, null, fileName);
    },
    [ACT_GET_LRN_BOARD](context, lrnPostSn){
        return new Promise(resolve => {
            ApiService.get('/v1/app/learns/boards', lrnPostSn).then(response => resolve(response));
        });
    },
    [ACT_GET_LRN_BOARD_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/boards', params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_LRN_BOARD](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/learns/boards', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_LRN_BOARD](context, {lrnPostSn, params}){
        return new Promise(resolve => {
            ApiService.update('/v1/app/learns/boards', lrnPostSn, params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_LRN_BOARD_SUMGO](context, {lrnPostSn, params}){
        return new Promise(resolve => {
            ApiService.update('/v1/app/learns/boards/sumgo', lrnPostSn, params).then(response => resolve(response));
        });
    },
    [ACT_DELETE_LRN_BOARD](context, lrnPostSn){
        return new Promise(resolve => {
            ApiService.delete('/v1/app/learns/boards', lrnPostSn).then(response => resolve(response));
        });
    },
    [ACT_GET_LRN_BOARD_CATE_LIST](){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/boards/cates', {}).then(response => resolve(response));
        });
    },
    [ACT_GET_LRN_REPORT](context, lrnTrgtGrpDtlSn){
        return new Promise(resolve => {
            ApiService.get('/v1/app/learns/reports', lrnTrgtGrpDtlSn).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_LRN_REPORT](context, {lrnTrgtGrpDtlSn, params}){
        return new Promise(resolve => {
            ApiService.update('/v1/app/learns/reports', lrnTrgtGrpDtlSn, params).then(response => resolve(response));
        });
    },
    [ACT_GET_LRN_BOARD_CMNT_LIST](context, lrnPostSn){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/learns/boards/${lrnPostSn}/cmnts`, {}).then(response => resolve(response));
        });
    },
    [ACT_INSERT_LRN_BOARD_CMNT](context, {lrnPostSn, params}){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/learns/boards/${lrnPostSn}/cmnts`, params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_LRN_BOARD_CMNT](context, {lrnPostCmntSn, params}){
        return new Promise(resolve => {
            ApiService.update('/v1/app/learns/boards/cmnts', lrnPostCmntSn, params).then(response => resolve(response));
        });
    },
    [ACT_DELETE_LRN_BOARD_CMNT](context, lrnPostCmntSn){
        return new Promise(resolve => {
            ApiService.delete('/v1/app/learns/boards/cmnts', lrnPostCmntSn).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_BOARD_WITH_COMMENT](context, lrnPostSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/learns/boards-cmnts', lrnPostSn).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/boards-cmnts', params).then(response => resolve(response));
        });
    },
    [ACT_GET_SOCS_EDITOR_UPLOAD_OCR_MASKING](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/socs/editor/upload/ocr/masking', params).then(response => resolve(response));
        });
    },
    [ACT_UPLOAD_LEARN_BOARD_IMAGE](contexts, {contentsType, files}){
        return ApiService.upload(`/v1/app/learns/boards/${contentsType}/upload`, files);
    },
    [ACT_UPLOAD_LEARN_BOARD_ATCH](contexts, {contentsType, files}){
        return ApiService.upload(`/v1/app/learns/boards/${contentsType}/upload/atch`, files, {}, true);
    },
    [ACT_INSERT_LRN_BOARD_LK](context, lrnPostSn){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/learns/boards/${lrnPostSn}/lks`).then(response => resolve(response));
        });
    },
    [ACT_DELETE_LRN_BOARD_LK](context, lrnPostSn){
        return new Promise(resolve => {
            ApiService.delete(`/v1/app/learns/boards/${lrnPostSn}`,'lks').then(response => resolve(response));
        });
    },
    [ACT_GET_LRN_BOARD_STATUS](context, distCrseSn) {
        return new Promise(resolve => {
            ApiService.get(`/v1/app/learns/${distCrseSn}/boards`, 'statuses').then(response => resolve(response));
        });
    },
    [ACT_GET_LRN_BOARD_EVENT_STATUS](context, {distCrseSn, distCrseGrpBoardTyDcd}) {
        return new Promise(resolve => {
            ApiService.get(`/v1/app/learns/${distCrseSn}/boards/statuses`, distCrseGrpBoardTyDcd).then(response => resolve(response));
        });
    },
    // 그룹러닝 모임 단건 조회
    [ACT_GET_GROUP_LRN_BOARD](context, distCrseSn){
        return new Promise(resolve => {
            ApiService.get('/v1/app/boards/group', distCrseSn).then(response => resolve(response));
        });
    },
    // 그룹러닝 모임일정 조회
    [ACT_GET_GROUP_LRN_BOARD_LIST](context, distCrseSn){
        return new Promise(resolve => {
            ApiService.get('/v1/app/boards/groups', distCrseSn).then(response => resolve(response));
        });
    },
    [ACT_GET_GROUP_LRN_BOARD_MNG](context, {boardSn, lrnPostSn}) {
        return new Promise(resolve => {
            ApiService.get(`/v1/app/learns/boards/mngs/${boardSn}/post`, lrnPostSn).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_GROUP_LRN_BOARD_MNG](context, {boardSn, params}) {
        return new Promise(resolve => {
            ApiService.update('/v1/app/learns/boards/mngs', boardSn, params).then(response => resolve(response));
        });
    },
    [ACT_GET_CHECK_PWD_GROUP_LRN_BOARD_MNG](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/boards/mngs/pwd', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_GROUP_SELF_INTRO](context, {lrnTrgtGrpDtlSn, params}){
        return new Promise(resolve => {
            ApiService.update('/v1/app/learns/boards/trgt', lrnTrgtGrpDtlSn, params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_GROUP_LRN_BOARD](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/learns/boards/mtgr`, params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_GROUP_LRN_BOARD](context, {lrnPostSn, params}){
        return new Promise(resolve => {
            ApiService.update(`/v1/app/learns/boards/mtgr`, lrnPostSn, params).then(response => resolve(response));
        });
    },
    [ACT_DELETE_GROUP_LRN_BOARD](context, {lrnPostSn, params}){
        return new Promise(resolve => {
            ApiService.put(`/v1/app/learns/boards/${lrnPostSn}/mtgr`, params).then(response => resolve(response));
        });
    },
    [ACT_DELETE_GROUP_LRN_TRGT_DTL](context, distCrseSn){
        return new Promise(resolve => {
            ApiService.delete(`/v1/app/learns/boards/trgt`, distCrseSn).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_GROUP_LEADER](context, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/learns/leaders`, params).then(response => resolve(response));
        });
    },
    [ACT_GET_BOARD_MNG](context, boardSn){
        return new Promise(resolve => {
            ApiService.get('/v1/app/boards/mngs', boardSn).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_BOARD_MNG](context, {boardSn, params}){
        return new Promise(resolve => {
            ApiService.update('/v1/app/boards/mngs', boardSn, params).then(response => resolve(response));
        });
    },
    [ACT_GET_GROUP_BOARD_TRGT](context, distCrseSn){
        return new Promise(resolve => {
            ApiService.get('/v1/app/boards/mngs/trgt', distCrseSn).then(response => resolve(response));
        });
    },
    [ACT_GET_GROUP_LRN_BOARD_STATUS](context, distCrseSn){
        return new Promise(resolve => {
            ApiService.get('/v1/app/learns/boards/grp', distCrseSn).then(response => resolve(response));
        });
    },
    [ACT_GET_GROUP_LRN_BOARD_STATUS_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/boards/status', params).then(response => resolve(response));
        });
    },
    [ACT_GET_GROUP_LRN_ATTN_STATUS_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/boards/attn/status', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_GROUP_LEARN_BOARD_CN](context, {lrnPostSn, params}){
        return new Promise(resolve => {
            ApiService.update('/v1/app/learns/group/boards', lrnPostSn, params).then(response => resolve(response));
        });
    },
    [ACT_GET_GROUP_LRN_MTQ_ACPT_LIST](context, distCrseSn){
        return new Promise(resolve => {
            ApiService.get('/v1/app/learns/boards/acpt', distCrseSn).then(response => resolve(response));
        });
    },
    [ACT_GET_LRN_BOARD_FILE_ATCH_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/lrns/boards/atchs/files', params).then(response => resolve(response))
        });
    },
    [ACT_GET_GROUP_LRN_BOARD_HRDCLOUD](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/contents', params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_PROG_LOG_LRN_STATUS](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/onclass/learn/status', params).then(response => resolve(response));
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
