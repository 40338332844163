<template>
  <main class="ssl-body" :class="{'ssl-preview' : previewVisible}" @click="toggleMenu(false)" style="padding-bottom: 0; !important;">
    <!-- 임시로 적용해봄... -->
    <div v-show="showOcrMaskingModal">
      <CheckOcrMaskingModal
          v-if="showOcrMaskingModal"
          v-model="showOcrMaskingModal"
          v-model:selected-value="textInfo"
          v-model:ocrImageUrl="ocrImageUrl"
      />
    </div>
    <div v-show="!showOcrMaskingModal">
      <SSLMobileWriteHeader
          :step="step"
          :go-func="goStep"
          :back-func="backStep"
          :validation-step="validationStep"
          :preview-visible="previewVisible"
      />
      <!-- 임시로 적용해봄... -->

      <SSLMobilePreview
          v-if="previewVisible"
          :soc-board="socBoard"
          :current-page-idx="currentPageIdx"
          :soc-pages="socPages"
          :hash-tags="hashTags"
      />
      <div v-show="!previewVisible && step === 0" class="page-container">
        <div class="thumbnail-container">
          <!-- image-wrap -->
          <div class="image-wrap">
            <input ref="uploadFile" v-show="false" type="file" @change="applyMobileThumbnail"/>
            <button class="image-btn" @click.stop="toggleMenu(true)">
              <img v-if="socBoard.thumb" :src="socBoard.thumb" alt="">
            </button>
            <!-- 썸네일 이미지 등록 메뉴 -->
            <!--  is-active 로 on/off 제어 -->
            <div class="editor thumbnail-edit-menu" :class="{'is-active': isMenuActive}">
              <ul class="menu-list">
                <li class="menu-item title">썸네일 이미지</li>
                <li class="menu-item">
                  <a href="javascript:" class="menu-link" @click="clickUploadFile">
                    <span class="menu-text">내 이미지</span>
                  </a>
                </li>
                <li class="menu-item">
                  <a href="javascript:" class="menu-link" @click="setRecommend">
                    <span class="menu-text">추천 이미지</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- //image-wrap -->
          <!-- title-wrap -->
          <div class="title-wrap">
            <p class="sub-title">{{ distCrseNm }}</p>
            <input ref="titleIns" v-model.trim="socBoard.title" type="text" class="input-title" placeholder="피드 제목 입력">
          </div>
          <!-- //title-wrap -->
          <!-- tags-wrap -->
          <div class="tags-wrap">
            <div class="tag-list">
              <div v-for="(hashTag, idx) in hashTags" class="tag-item is-active" :key="idx">
                <span class="tag-item-text">{{ hashTag }}</span>
                <button class="kb-btn-tag" @click="deleteHashTag(idx)">
                  <i class="icon-tag-delete"></i>
                </button>
              </div>
              <div class="tag-item tag-input">
                <input v-model.trim="hashTag" type="text" class="tag-item-input" placeholder="태그" @keyup.enter="addHashTag">
              </div>
            </div>
          </div>
          <!-- //tags-wrap -->
          <!-- source-wrap -->
          <SSLMobileWriteRel
              :socLrnPostSn="Number(socLrnPostSn)"
              v-model:ref-post="socBoard.refPost"
              v-model:ref-url="socBoard.refUrl"
          />
          <!-- //source-wrap -->
        </div>
      </div>

      <div v-show="!previewVisible && step === 1" class="page-container">
        <div class="editor-container">
          <!-- editor-body -->
          <div class="editor-body">
            <div class="editor-body-top">
              <input v-model.trim="socBoard.title" type="text" placeholder="제목">
              <button type="button" class="kb-btn-editor-more" @click.stop="toggleMenu(true)">
                <i class="icon-more"></i>
              </button>
              <!-- 썸네일 이미지 등록 메뉴 -->
              <!--  is-active 로 on/off 제어 -->
              <div class="editor content-edit-menu" :class="{'is-active': isMenuActive}">
                <ul class="menu-list">
                  <li class="menu-item">
                    <a href="javascript:" class="menu-link" @click="togglePreviewVisible(true)">
                      <span class="menu-text">미리보기</span>
                    </a>
                  </li>
                  <li class="menu-item">
                    <a v-if="stt === '' || stt === '01'" href="javascript:" class="menu-link" @click="clickSaveButton(true)">
                      <span class="menu-text">임시저장</span>
                    </a>
                  </li>
                  <li class="menu-item">
                    <a v-if="socLrnPostSn > 0 && stt === '01'" href="javascript:" class="menu-link" @click="clickDeleteButton">
                      <span class="menu-text">삭제</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="editor-body-content" style="background: #FFFFFF">
              <div v-show="false" id="editorTextarea" ref="pageEditor"></div>
              <iframe v-if="isInApp" ref="editorIframe" width="100%" height="100%"></iframe>
            </div>

            <div class="editor-content-footer" style="margin-top: 5px">
              <div class="content-byte">
                <span class="current">{{ textCount }}</span>
                <span class="total">{{ maxTextLen }}</span>
              </div>
            </div>
          </div>
          <!-- //editor-body -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SSLMobileWriteRel from '@/components/ssl/write/mobile/SSLMobileWriteRel';
import SSLMobileWriteHeader from '@/components/ssl/write/mobile/SSLMobileWriteHeader';
import SSLMobilePreview from '@/components/ssl/write/mobile/SSLMobilePreview';
import {sslWriteSetup} from '@/assets/js/modules/ssl/setup/ssl-write-setup';
import CheckOcrMaskingModal from "@/components/support/CheckOcrMaskingModal.vue";

export default {
  name: 'SSLMobileWrite',
  components: {
    CheckOcrMaskingModal,
    SSLMobilePreview,
    SSLMobileWriteHeader,
    SSLMobileWriteRel
  },
  setup: sslWriteSetup
}
</script>
