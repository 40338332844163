<template>
  <main v-if="modelValue" class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="support-view-container">
        <div class="view-contents">
          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">신청정보</h4>
            </header>
            <div class="section-content">
              <MobileApplicant :col-type="true"/>
              <div class="content-item">
                <h4 class="title">교육기관 구분</h4>
                <template v-if="!isReadOnly" >
                  <div v-for="(item,idx) in eduInstDivCds" :key="idx" class="chk-cont">
                    <label :for="`chk_01_${idx}`" class="item-agree">
                      {{ item.nm }}
                      <div class="kb-form-check">
                        <input type="radio" name="chk_cancel" class="kb-form-check-input" checked="" :id="`chk_01_${idx}`" v-model="param.eduInstDivCd" :value="item.val" @change="selectEduInst(idx)">
                      </div>
                    </label>
                  </div>
                </template>
                <div v-else class="chk-cont">
                  <label :for="`chk_01`" class="item-agree">
                    {{ param.eduInstNm }}
                    <div class="kb-form-check">
                      <input type="radio" name="chk_cancel" class="kb-form-check-input" :id="`chk_01`" v-model="param.eduInstDivCd" :disabled="isReadOnly" :value="param.eduInstDivCd">
                    </div>
                  </label>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:교육기관 -->
                  <div class="kb-form-item">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">교육기관명</span>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input v-model="param.eduInstNm" type="text" class="kb-form-control" placeholder="교육기관명을 입력하세요" :disabled="isReadOnly" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:과정명 -->
                  <div class="kb-form-item">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">과정명</span>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input v-model="param.offcrCrseNm" type="text" class="kb-form-control" placeholder="과정명을 입력하세요" :disabled="isReadOnly">
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-item ">
                <div class="kb-form-fields">
                  <RowCustomDatepicker
                      title="교육시작기간"
                      v-model="param.crseBgngDd"
                      v-model:toggle="toggles.crseBgngDd"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'crseBgngDd')"
                  />
                  <RowCustomDatepicker
                      title="교육종료기간"
                      v-model="param.crseEndDd"
                      v-model:toggle="toggles.crseEndDd"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'crseEndDd')"
                  />
                </div>
              </div>

              <section v-if="isConfirmation" class="content-section">
                <header class="section-header">
                  <h4 class="title">확약서(6개월이상 과정)</h4>
                </header>
                <div class="section-content">
                  <div class="content-item">
                    <div class="desc">
                      <p>본 과정 종료 시점부터 해당 교육기간을 초과하여 근무하지 아니하는 때에는 지급받은 금액을 반납할 것을 서약합니다. <br/>
                        다만, 의무근무기간의 일부만을 이행하였을 경우 이행하지 아니한 잔여 의무근무기간을 총 의무근무기간에 비례하여 반납합니다.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="content-item">
                  <label for="chk_agree_03" class="item-agree">
                    상기내용을 숙지하였으며 동의합니다.
                    <div class="kb-form-check" @click="confirmation=!confirmation">
                      <input v-model="confirmation" type="checkbox" class="kb-form-check-input" id="chk_agree_04">
                    </div>
                  </label>
                </div>
              </section>

              <div class="content-item">
                <div class="kb-form-fields">
                  <RowCustomDatepicker
                      title="등록금 납입일"
                      v-model="param.crseTuitFeePayDd"
                      v-model:toggle="toggles.crseTuitFeePayDd"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'crseTuitFeePayDd')"
                  />
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:납입금액 -->
                  <div class="kb-form-item">
                    <MobileRowInputText
                        v-model="param.crsePayAmt"
                        :is-number="true"
                        :is-money="true"
                        title="납입금액"
                        placeholder="납입금액을 입력하세요"
                        :disabled="isReadOnly"
                        kb-form-out-class="kb-form-column kb-form-column-title"
                        kb-form-in-class="kb-form-column column-whole"
                    />
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:지급예상금액 -->
                  <div class="kb-form-item">
                    <MobileRowInputText
                        v-model="param.sprtExptAmt"
                        :is-number="true"
                        :is-money="true"
                        title="지급예상금액"
                        :disabled="isReadOnly"
                        kb-form-out-class="kb-form-column kb-form-column-title"
                        kb-form-in-class="kb-form-column column-whole"
                    />
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:등록금 납입증명서 -->
                  <div class="kb-form-item">
                    <MobileUpload
                        v-model="tuitPayCertFiles"
                        title="등록금 납입증명서"
                        placeholder="등록급 납입증명서를 등록하세요"
                        btn-title="등록금 납입증명서 등록하기"
                        sequence-key="proofDataSn"
                        :extensions="extensions"
                        :max-qty="10"
                        :is-payment="true"
                        :disabled="isReadOnly"
                    />
                  </div>
                </div>
              </div>
              <div class="content-item">
                <h4 class="title">지원자격 구분</h4>
                <template v-if="!isReadOnly">
                  <div v-for="(item, idx) in sprtQlfcDivCds" :key="idx" class="chk-cont">
                    <label :for="`chk_cancel1_${idx}`" class="item-agree">
                      {{ item.nm }}
                      <template v-if="(param.sprtQlfcDivCd == '3' || param.sprtQlfcDivCd == '4') && param.sprtQlfcDivCd==item.val">
                        (수상년도:<input type="text" v-model="param.awardYear" class="kb-form-control w-25 p-1">년)
                      </template>
                      <div class="kb-form-check">
                        <input v-model="param.sprtQlfcDivCd"  type="radio" name="chk_cancel1" class="kb-form-check-input"  :id="`chk_cancel1_${idx}`" :value="item.val" @change="selectSprtQlfc(idx)" />
                      </div>
                    </label>
                  </div>
                </template>
                <div v-else class="chk-cont">
                  <label :for="`chk_cancel1`" class="item-agree">
                    {{ param.sprtQlfcDivNm }}
                    <template v-if="(param.sprtQlfcDivCd == '3' || param.sprtQlfcDivCd == '4') && param.sprtQlfcDivCd==item.val">
                      (수상년도: {{param.awardYear}}년)
                    </template>
                    <div class="kb-form-check">
                      <input v-model="param.sprtQlfcDivCd" type="radio" name="chk_cancel1" class="kb-form-check-input" :id="`chk_cancel1`"  :disabled="isReadOnly" :value="param.sprtQlfcDivCd" />
                    </div>
                  </label>
                </div>

              </div>
              <div class="content-item">
                <h4 class="title">지원사유</h4>

                <template v-if="!isReadOnly" >
                  <div v-for="(item,idx) in sprtRsnDivCds" :key="idx" class="chk-cont">
                    <label :for="`chk_cancel2_${idx}`" class="item-agree">
                      {{ item.nm }}
                      <div class="kb-form-check">
                        <input v-model="param.sprtRsnDivCd" type="radio" name="chk_cancel2" class="kb-form-check-input" :id="`chk_cancel2_${idx}`" :value="item.val" @change="selectSprtRsn(idx)" />
                      </div>
                    </label>
                  </div>
                </template>
                <div v-else class="chk-cont">
                  <label :for="`chk_cancel2`" class="item-agree">
                    {{ param.sprtRsnDivNm }}
                    <div class="kb-form-check">
                      <input v-model="param.sprtRsnDivCd" type="radio" name="chk_cancel2" class="kb-form-check-input" checked="" :id="`chk_cancel2`" >
                    </div>
                  </label>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:사유작성 -->
                  <div class="kb-form-item">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">사유작성</span><span class="text-count">0/300</span>
                    </label>
                    <div class="kb-form-column column-whole">
                      <textarea  v-model="param.sprtRsn" class="" :disabled="isReadOnly"
                                 placeholder="사유를 입력해주세요."></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-item">
                <h4 class="title">과세여부</h4>

                <template v-if="!isReadOnly" >
                  <div class="chk-cont">
                    <label class="item-agree">과세
                      <div class="kb-form-check">
                        <input v-model="param.taxYn" type="radio" class="kb-form-check-input" value="Y" disabled />
                      </div>
                    </label>
                  </div>
                  <div class="chk-cont">
                    <label class="item-agree">비과세
                      <div class="kb-form-check">
                        <input v-model="param.taxYn" type="radio" class="kb-form-check-input" value="N" disabled />
                      </div>
                    </label>
                  </div>
                </template>

                <div v-else class="chk-cont">
                  <label class="item-agree">
                    {{ `param.taxYn=="Y"?'과세':'비과세'` }}
                  </label>
                </div>
              </div>
            </div>
          </section>



          <section class="content-section" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
            <header class="section-header header-divider">
              <h4 class="title">환입 및 추가지원금액</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2" v-if="param.rtnYn=='Y'">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">환입금액</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <input type="text" class="kb-form-control"  :value="numberComma(param.rtnAmt)" readonly>
                  <div class="kb-form-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
              </div>
              <div class="kb-form-fields kb-form-fields-v2" v-if="param.addAmtYn=='Y'">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">추가지원금액</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <input type="text" class="kb-form-control" :value="numberComma(param.addAmt)" readonly>
                  <div class="kb-form-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- content-section -->
          <section v-if="data == null" class="content-section">
            <UserAgreementMobile
                ref="usrAgrmntRef"
                v-model:user-agreement="userAgreement"
                @applyBtnEnable="applyBtnEnable"
            />
<!--            <header class="section-header">-->
<!--              <h4 class="title">증빙서류 제출시 유의사항</h4>-->
<!--            </header>-->
<!--            <div class="section-content">-->
<!--              <div class="content-item">-->
<!--                <div class="desc">-->
<!--                  <p>1. 자기주도학습비는 직원의 자기계발 및 업무능력향상을 위해 은행재원으로 지원되고 있습니다. 본 제도의 취지에 맞지 않는 부당수령사례(환불/대리수강/증명서위변조/사은품 수령 등) 확인시 소속부점통보, 자기주도학습비 지원제한 및 각종 연수 참가 제한 조치되며 사안이 중대한 경우 인사조치 될 수 있습니다. 상기 내용을 숙지 하였음을 확인합니다.-->
<!--                    <br>-->
<!--                    2. 신청내용 검증을 위한 담당무서의 자료제출 및 개인정보 조회에 동의합니다.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="content-item">-->
<!--              <label for="chk_agree_03" class="item-agree">-->
<!--                상기내용을 숙지하였으며 동의합니다.-->
<!--                <div class="kb-form-check" @click="userAgreement=!userAgreement">-->
<!--                  <input v-model="userAgreement" type="checkbox" class="kb-form-check-input" id="chk_agree_03">-->
<!--                </div>-->
<!--              </label>-->
<!--            </div>-->
          </section>
        </div>
        <MobileSupportButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
        />
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import MobileApplicant from "@/components/support/mobile/MobileApplicant";
import {SupportTuitionSubSetup} from "@/assets/js/modules/support/support-tuition-sub-setup";
import RowCustomDatepicker from "@/components/support/common/RowCustomDatepicker";
import MobileRowInputText from "@/components/support/common/mobile/MobileRowInputText";
import MobileUpload from "@/components/support/common/MobileUpload";
import MobileSupportButton from "@/components/support/common/mobile/MobileSupportButton";
import UserAgreementMobile from "@/components/support/common/mobile/UserAgreementMobile";


export default {
  name: "SupportTuitionSubMobileModal",
  components: {MobileSupportButton, MobileUpload, MobileRowInputText, RowCustomDatepicker, MobileApplicant, UserAgreementMobile},
  props: {
    modelValue: Boolean,
    data: {type:Object, default: null},
    reList : Function
  },
  setup: SupportTuitionSubSetup
}
</script>