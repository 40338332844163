<template>
  <main v-if="(modelValue && !collegeModal && !majorModal)" class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="support-view-container">
        <div class="view-contents">
          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">신청정보</h4>
            </header>
            <div class="section-content">
              <MobileApplicant :col-type="true"/>
              <!-- kb-form-fields -->
              <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
                <!-- kb-form-field:학교구분 -->
                <div class="kb-form-field">
                  <MobileInputSelect
                      v-model:value1="param.schlDivCdDcd"
                      v-model:toggle1="toggles.schlDivCdDcds"
                      :options1="schlDivCdDcdOpt"
                      :disabled="isReadOnly"
                      title="학교구분"
                      :width="358"
                      sequence-key1="cd"
                      name-key1="cdNm"
                      placeholder1="학교구분 선택"
                      @update:toggle1="closeToggles(toggles, 'schlDivCdDcds')"
                  />
                </div>
                <div class="kb-form-field">
                  <MobileInputSelect
                      v-model:value1="param.schlInDivCdDcd"
                      v-model:toggle1="toggles.schlInDivCdDcds"
                      title="학기(학년)재학 구분"
                      :options1="schlInDivCdDcdOpt"
                      sequence-key1="cd"
                      name-key1="cdNm"
                      placeholder1="재학구분 선택"
                      :disabled="isReadOnly"
                      :width="171"
                      @update:toggle1="closeToggles(toggles, 'schlInDivCdDcds')"
                  />
                </div>
                <div v-if="isAcademicCreditBank" class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">수강학점</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input
                        type="text"
                        class="kb-form-control"
                        v-model="param.regCrseCrdit"
                        :disabled="isReadOnly"
                        maxlength="10"
                        @input="numbersOnly"
                        @focusin="emptyNumberOnClick"
                        @focusout="emptyNumberOnClick"
                    />
                    <span class="kb-form-subtext">수강가능학점 : {{sprtCredit}}점</span>
                  </div>
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">학위취득예정시기</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input
                        type="text"
                        class="kb-form-control"
                        maxlength="20"
                        v-model="param.degAcqsSchedPerid"
                        :disabled="isReadOnly"
                    />
                  </div>
                </div>
                <!-- kb-form-field:학교명 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">학교명</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-search" @click="searchSchool('college')">
                      <input type="text" v-model="school.scholNm" class="kb-form-control" placeholder="학교명 검색" disabled />
                      <button class="kb-form-search-btn"><i class="icon-search"></i></button>
                    </div>
                  </div>
                </div>
                <!-- kb-form-field:전공 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">전공</span>
                    </label>
                  </div>
                  <div class="kb-form-search" @click="searchSchool('major')">
                    <input type="text" v-model="major.majorNm" class="kb-form-control" placeholder="전공 검색" disabled />
                    <button class="kb-form-search-btn"><i class="icon-search"></i></button>
                  </div>
                </div>
                <div class="kb-form-field">
                  <MobileRowInputText
                      v-model="param.majorNm2"
                      title="학과"
                      placeholder="학과를 입력하세요"
                      :disabled="isReadOnly"
                  />
                </div>
                <!-- kb-form-field:등록금 납부일 -->
                <div class="kb-form-field" v-if="!isAcademicCreditBank">
                  <MobileInputSelect
                      title="신청학기"
                      v-model:value1="param.sprtYear"
                      v-model:toggle1="toggles.sprtYears"
                      :options1="appYear"
                      sequence-key1="val"
                      nameKey1="val"
                      placeholder1="년도"
                      @update:toggle1="closeToggles(toggles, 'sprtYears')"
                      v-model:value2="param.termDivCdDcd"
                      v-model:toggle2="toggles.termDivCdDcds"
                      :options2="termDivCdDcdOpt"
                      sequence-key2="cd"
                      nameKey2="cdNm"
                      placeholder2="학기 선택"
                      @update:toggle2="closeToggles(toggles, 'termDivCdDcds')"
                      :useSubText="false"
                  />
                  <div class="kb-form-row">
                    <span class="kb-form-subtext">* 수강기간에 휴직/정직기간이 포함된 경우 지원 불가</span>
                  </div>
                </div>
                <!-- kb-form-field:등록금 납부일 -->
                <div class="kb-form-field">
                  <RowCustomDatepicker
                      title="학기 종료일(종강일)"
                      v-model="param.termEndDd"
                      v-model:toggle="toggles.termEndDd"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'termEndDd')"
                  />
                </div>
                <!-- kb-form-field:직전학기 평점평균 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">신청학기 평점평균</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input
                        type="text" class="kb-form-control"
                        v-model="param.grdAvg"
                        :disabled="isReadOnly"
                        @input="numbersOnly"
                        @focusin="emptyNumberOnClick"
                        @focusout="emptyNumberOnClick"
                    />
                    <div class="kb-form-dropdown left-margin" data-offset="bottom-left">
                      <div class="kb-form-dropdown" :class="{'is-active': toggles.grdTots}">
                        <button class="kb-form-dropdown-btn" @click.stop="toggleGrdTot" :disabled="isReadOnly">
                          <span class="text">{{ param.grdTot }}점</span><i class="icon-arrow"></i>
                        </button>
                        <div class="kb-form-dropdown-options" style="width: 166px;">
                          <div class="dropdown-option">
                            <ul class="dropdown-option-list">
                              <li v-for="(item, idx) in grdTots2" class="dropdown-option-item" :key="idx">
                                <a href="javascript:" class="dropdown-option-link" @click.stop="selectGrdTot2(idx)"><span class="dropdown-option-text">{{ item.nm }}</span></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //kb-form-field -->

                <div class="kb-form-filed">
                  <MobileUpload
                      v-model="transcriptFiles"
                      title="신청학기 성적증명서"
                      placeholder="신청학기 성적증명서를 등록하세요"
                      btn-title="성적증명서 등록하기"
                      sequence-key="proofDataSn"
                      subtext="*1장만 등록가능함, 증명서의 가장자리가 모두 보이도록 촬영"
                      :extensions="extensions"
                      :max-qty="10"
                      :is-payment="true"
                      :disabled="isReadOnly"
                  />
                </div>
                <div class="kb-form-filed">
                  <MobileRowInputText
                      v-model="param.inetProofNo"
                      title="인터넷 증명서 발급번호"
                      placeholder="진위여부 확인이 가능한 일련번호를 입력하세요. "
                      :disabled="isReadOnly"
                  />
                  <div class="kb-form-row">
                    <span class="kb-form-subtext">* 인터넷 증명서 발급번호(숫자,영문,- 만 입력가능)</span>
                  </div>
                </div>
              </div>
              <!-- //kb-form-fields -->
            </div>
          </section>

          <section class="content-section">
            <header class="section-header">
              <h4 class="title">등록금 납부 내역 등록</h4>
            </header>
            <div class="section-content">
              <!-- accordion-container-v2 -->
              <div class="accordion-container-v2">
                <!-- accordion:납부 금액 -->
                <div class="accordion"  :class="{ 'is-active' : showPrice }">
                  <!-- accordion-toggle-action -->
                  <a href="javascript:" class="accordion-toggle-action" @click="showPrice=!showPrice">
                    <div class="accordion-row">
                      <div class="accordion-column">
                        <div class="accordion-title">고지금액</div>
                      </div>
                      <div class="accordion-icon"><i class="icon-arrow"></i></div>
                    </div>
                  </a>
                  <!-- accordion-toggle -->
                  <div class="accordion-target">
                    <div class="accordion-sub">
                      <!-- sub-section:지원 대상 금액 -->
                      <article class="sub-section">
<!--                        <header class="section-header header-divider">-->
<!--                          <h4 class="title">지원 대상 금액</h4>-->
<!--                        </header>-->
                        <div class="kb-form-fields kb-form-fields-v2">
                          <div class="kb-form-field field-first" v-if="param.schlInDivCdDcd != schlInDivCdDcds.SCHL_IN_DIV_CD_OLD">
                            <MobileRowInputText
                                v-model="fees.sprtEntrFee"
                                title="입학금"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            />
                          </div>
                          <!-- kb-form-field:수업료 ・ 등록금 -->
                          <div class="kb-form-field" :class="{'field-first' : param.schlInDivCdDcd == schlInDivCdDcds.SCHL_IN_DIV_CD_OLD}">
                            <MobileRowInputText
                                v-model="fees.sprtTuitFee"
                                title="수업료"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            />
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                  <!-- //accordion-toggle -->
                </div>
                <!-- accordion:감면 금액 -->
                <div class="accordion" :class="{ 'is-active' : showReducedPrice }">
                  <!-- accordion-toggle-action -->
                  <a href="javascript:" class="accordion-toggle-action" @click="showReducedPrice=!showReducedPrice">
                    <div class="accordion-row">
                      <div class="accordion-column">
                        <div class="accordion-title">장학금액</div>
                      </div>
                      <div class="accordion-icon"><i class="icon-arrow"></i></div>
                    </div>
                  </a>
                  <!-- accordion-toggle -->
                  <div class="accordion-target">
                    <div class="accordion-sub">
                      <!-- sub-section:장학금-->
                      <article class="sub-section">
                        <div class="kb-form-fields kb-form-fields-v2">
                          <!-- kb-form-field:성적 -->
                          <div class="kb-form-field field-first">
                            <MobileRowInputText
                                v-model="reduce.redGradeBursAmt"
                                title="교내 성적장학금"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            />
                          </div>
                          <!-- kb-form-field:그외 장학금-->
                          <div class="kb-form-field">
                            <MobileRowInputText
                                v-model="reduce.etcBursAmt"
                                title="그외 장학금"
                                :is-number="true"
                                :is-money="true"
                                :is-kb-form-side="true"
                                kb-form-side-text="원"
                                :disabled="isReadOnly"
                            />
                          </div>
                          <!-- kb-form-field:감면금액 합계 -->

                          <!-- kb-form-field:기타 -->
<!--                          <div class="kb-form-field">-->
<!--                            <MobileRowInputText v-model="reduce.etcBursAmt" class-lst="column-auto" placeholder="금액을 입력하세요." :is-number="true" :is-money="true" :is-kb-form-side="true" kb-form-side-text="원" :disabled="isReadOnly">-->
<!--                              <template v-slot:first>-->
<!--                                <div class="kb-form-column kb-form-column-title">-->
<!--                                  <input type="text" class="kb-form-control me-3" v-model="reduce.etcBursAmtNm" placeholder="기타 장학금명을 입력하세요"/>-->
<!--                                </div>-->
<!--                              </template>-->
<!--                            </MobileRowInputText>-->
<!--                          </div>-->
                        </div>
                      </article>
                      <!-- //sub-section -->
                    </div>
                  </div>
                  <!-- //accordion-toggle -->
                </div>
                <!-- //accordion -->
              </div>
              <!-- kb-form-fields -->
              <div class="kb-form-fields kb-form-fields-v2 mt-3">
                <!-- kb-form-field:지원대상 금액 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">지원대상 금액</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input type="text" class="kb-form-control" :value="numberComma(param.sprtExptAmt)" readonly>
                    <div class="kb-form-side">
                      <span class="kb-form-text">원</span>
                    </div>
                  </div>
                </div>
                <!-- kb-form-field:지원금액 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">지원예상 금액</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <input type="text" class="kb-form-control" placeholder="지원금액을 입력하세요" :value="numberComma(param.sprtAmt)" readonly>
                    <div class="kb-form-side"><span class="kb-form-text">원</span></div>
                  </div>
                  <div class="kb-form-row">
                    <span class="kb-form-subtext">* 지원대상금액의 50%(단, 본인 납부금액 내에서만 지원)</span>
                  </div>
                </div>
                <!-- kb-form-field:등록금 납입증명서 -->
                <div class="kb-form-field">
                  <MobileUpload
                      v-model="tuitPayCertFiles"
                      title="등록금 납입증명서(교육기관 발급)"
                      placeholder="등록금 납입증명서(교육기관 발급)를 등록하세요"
                      btn-title="등록금 납입증명서 등록하기"
                      :extensions="extensions"
                      :max-qty="10"
                      sequence-key="proofDataSn"
                      :disabled="isReadOnly"
                  />
                </div>
                <!-- //kb-form-field -->
              </div>
            </div>
          </section>


          <!-- content-section:결과등록 -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">결과등록</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2">
                <!-- kb-form-field:장학금 비수혜증명서 -->
                <div class="kb-form-field">
                  <MobileUpload
                      v-model="schlScholarCertFiles"
                      title="장학금 (비)수혜 증명서"
                      btn-title="증명서 등록하기"
                      sequence-key="proofDataSn"
                      :extensions="extensions"
                      :max-qty="10"
                      :is-payment="true"
                      :key="`tuition`"
                      :disabled="isReadOnly"
                  />
                  <div class="kb-form-row">
                    <span class="kb-form-subtext">* 장학금 (비)수혜 증명서(교육기관 발급) 또는 연말정산용 교육비 납입증명서</span>
                  </div>
                </div>
                <!-- //kb-form-field -->
              </div>
            </div>
          </section>

          <section class="content-section" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
            <header class="section-header header-divider">
              <h4 class="title">환입 및 추가지원금액</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2" v-if="param.rtnYn=='Y'">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">환입금액</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <input type="text" class="kb-form-control"  :value="numberComma(param.rtnAmt)" readonly>
                  <div class="kb-form-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
              </div>
              <div class="kb-form-fields kb-form-fields-v2" v-if="param.addAmtYn=='Y'">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">추가지원금액</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <input type="text" class="kb-form-control" :value="numberComma(param.addAmt)" readonly>
                  <div class="kb-form-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="content-section" v-if="data == null">
            <UserAgreementMobile
                ref="usrAgrmntRef"
                v-model:user-agreement="userAgreement"
                @applyBtnEnable="applyBtnEnable"
            />
          </section>
        </div>
        <MobileSupportButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
        />
      </div>
    </div>
    <!-- //main-content -->
  </main>

  <MobileLicenseSearch
      v-if="collegeModal"
      v-model="collegeModal"
      v-model:selectedValue="school"
      list-type="college"
      select-button-text="학교선택"
      placeholder="학교검색"
  />
  <MobileLicenseSearch
      v-if="majorModal"
      v-model="majorModal"
      v-model:selectedValue="major"
      list-type="major"
      select-button-text="전공선택"
      placeholder="전공검색"
  />
</template>

<script>
import {supportTuitionVupSetup} from "@/assets/js/modules/support/support-tuition-vup-setup";
import MobileLicenseSearch from "@/components/support/mobile/MobileSchoolSearch";
import MobileUpload from "@/components/support/common/MobileUpload";
import MobileRowInputText from "@/components/support/common/mobile/MobileRowInputText";
import MobileInputSelect from "@/components/support/common/mobile/MobileInputSelect";
import MobileApplicant from "@/components/support/mobile/MobileApplicant";
import MobileSupportButton from "@/components/support/common/mobile/MobileSupportButton";
import UserAgreementMobile from "@/components/support/common/mobile/UserAgreementMobile";
import RowCustomDatepicker from "@/components/support/common/RowCustomDatepicker";

export default {
  name: "SupportTuition202309MobileModal",
  components: {
    MobileSupportButton, MobileApplicant,
    MobileInputSelect, MobileRowInputText, MobileUpload, MobileLicenseSearch, UserAgreementMobile, RowCustomDatepicker},
  props: {
    modelValue: Boolean,
    data: {type:Object, default:null},
    reList : Function
  },
  setup: supportTuitionVupSetup
}
</script>