<template>
  <div class="floating-btn" :class="{'is-active' : showDropdown}">
    <button class="floating-btn-link" @click="toggleDropDown">
      <i class="icon-writing"></i><p style="font-size: 12px; color: white;">SSL</p>
    </button>
    <template v-if="showDropdown">
      <SSLMobileNavWrt v-if="isMobile" :effect-func="effectFunc" />
      <SSLNavWrt v-else />
    </template>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import SSLNavWrt from "@/components/ssl/SSLNavWrt.vue";
import SSLMobileNavWrt from "@/components/ssl/mobile/SSLMobileNavWrt.vue";
import {getNecessaries} from "@/assets/js/modules/ssl/ssl-common";
import {isSSLPeriod} from "@/assets/js/modules/ssl/ssl-write";
import {useAlert} from "@/assets/js/modules/common/alert";
import {limitSSL} from "@/assets/js/modules/ssl/ssl-message";
export default {
  name: 'SSLWriteButton',
  props: {
    effectNav: Number,
    effectFunc: Function,
  },
  components: {SSLNavWrt, SSLMobileNavWrt},
  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const showDropdown = ref(false);

    const {showMessage} = useAlert();

    const toggleDropDown = () => {
      if(!isSSLPeriod()){
        showMessage( limitSSL);
        return false;
      }
      if(showDropdown.value){
        showDropdown.value = false;
      }else{
        if(props.effectFunc instanceof Function){
          props.effectFunc();
        }
        setTimeout(() => {showDropdown.value = true;}, 100);
      }
    }

    watch(() => props.effectNav, () => {
      showDropdown.value = false;
    })

    onMounted(getNecessaries)

    return {
      isMobile,
      showDropdown,
      toggleDropDown,
    }
  }
}
</script>
