<template>
  <div class="dropdown-target dropdown-depthmemu">
    <div class="dropdown-box">
      <div class="depthmenu" data-depth="1">
        <!-- 2nd Category -->
        <div class="depth-column">
          <ul class="menu-list">
            <li class="menu-item" v-for="(one, idx) in category2" :key="idx"
                :class="{ 'is-active' : one.hrdCloudCateSn == toggleLvl2.hrdCloudCateSn }">
              <a href="javascript:" class="menu-link"
                 :class="[{ 'new' : one.newCnt > 0 }, {'has-sibling' : one.hasSibling}]"
                 @click.stop="selectOptions(one.hrdCloudCateSn, 2)">
                <span class="text">{{ one.hrdCloudCateNm }}</span>
              </a>
            </li>
          </ul>
        </div>
        <!-- 3rd Category -->
        <div class="depth-column" v-if="(toggleLvl2.hrdCloudCateSn > 0 && category3.length > 0)">
          <ul class="menu-list">
            <li class="menu-item" v-for="(second, idx) in category3" :key="idx"
                :class="{ 'is-active' : second.hrdCloudCateSn == toggleLvl3.hrdCloudCateSn }">
              <a href="javascript:" class="menu-link"
                 :class="[{ 'new' : second.newCnt > 0 }, {'has-sibling' : second.hasSibling}]"
                 @click.stop="selectOptions(second.hrdCloudCateSn, 3)">
                <span class="text">{{ second.hrdCloudCateNm }}</span>
              </a>
            </li>
          </ul>
        </div>
        <!-- 4th Category -->
        <div class="depth-column" v-if="toggleLvl3.hrdCloudCateSn > 0">
          <ul class="menu-list">
            <li class="menu-item" v-for="(third, idx) in category4" :key="idx"
                :class="{ 'is-active' : third.hrdCloudCateSn == toggleLvl4.hrdCloudCateSn }">
              <a href="javascript:" class="menu-link"
                 :class="[{ 'new' : third.newCnt > 0 }, {'has-sibling' : third.hasSibling}]"
                 @click.stop="selectOptions(third.hrdCloudCateSn, 4)">
                <span class="text">{{ third.hrdCloudCateNm }}</span>
              </a>
            </li>
          </ul>
        </div>
        <!-- 5th Category -->
        <div class="depth-column" v-if="toggleLvl4.hrdCloudCateSn > 0">
          <ul class="menu-list">
            <li class="menu-item" v-for="(fourth, idx) in category5" :key="idx"
                :class="{ 'is-active' : fourth.hrdCloudCateSn == toggleLvl5.hrdCloudCateSn }">
              <a href="javascript:" class="menu-link"
                 :class="{ 'new' : fourth.newCnt > 0 }"
                 @click.stop="selectOptions(fourth.hrdCloudCateSn, 5)">
                <span class="text">{{ fourth.hrdCloudCateNm }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  #kb-hrdcloud .dropdown-depthmemu .menu-link {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 44px;
    border-radius: var(--kb-border-radius);
    background-color: transparent;
    padding: 12px 16px;
    font: normal 16px/20px var(--kb-font-KBFGTextB);
    padding-right: 40px;
  }
  #kb-hrdcloud .dropdown-depthmemu .menu-link.has-sibling {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 44px;
    border-radius: var(--kb-border-radius);
    background-color: transparent;
    padding: 12px 16px;
    font: normal 16px/20px var(--kb-font-KBFGTextB);
    background: url(~@/assets/lxp/images/common/ic_arrow16_right.svg) no-repeat calc(100% - 16px) 50%;
    padding-right: 40px;
  }
</style>
<script>
import {computed, reactive, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import {initParams} from '@/assets/js/util';
import {pushQuery} from '@/assets/js/ui.init';

export default {
  name: 'HrdCloudCategory',
  props: {
    toggle: Boolean,
  },
  emits: ['update:toggle'],
  setup(props, {emit}) {
    const store = useStore();
    const route = useRoute();

    const searchParam = reactive({
      category2: 0,
      category3: 0,
      category4: 0,
      category5: 0,
      pageNo: 1
    });

    const toggleLvl1 = ref({ hrdCloudCateSn: 0, activate: false }),
          toggleLvl2 = ref({ hrdCloudCateSn: 0, activate: false }),
          toggleLvl3 = ref({ hrdCloudCateSn: 0, activate: false }),
          toggleLvl4 = ref({ hrdCloudCateSn: 0, activate: false }),
          toggleLvl5 = ref({ hrdCloudCateSn: 0, activate: false });

    // search requestParam에서 가져와서 넣어줘야함
    const category = computed(() => store.state.hrdcloud.hrdcloudCates);
    // const category1 = computed(() => category.value.filter(x => x.lvl === 1 && x.stt === '00'));
    const category1 = ref(0);
    const category2 = computed(() => category.value.filter(x => x.lvl === 2 && x.upHrdCloudCateSn === category1.value && x.stt === '00').map(x => {
      if (category.value.find(y => y.lvl === 3 && y.upHrdCloudCateSn === x.hrdCloudCateSn && y.stt === '00')) {
        x.hasSibling = true;
      } else {
        x.hasSibling = false;
      }
      return x;
    }));
    const category3 = computed(() => category.value.filter(x => x.lvl === 3 && x.upHrdCloudCateSn === searchParam.category2 && x.stt === '00').map(x => {
      if (category.value.find(y => y.lvl === 4 && y.upHrdCloudCateSn === x.hrdCloudCateSn && y.stt === '00')) {
        x.hasSibling = true;
      } else {
        x.hasSibling = false;
      }
      return x;
    }));
    const category4 = computed(() => category.value.filter(x => x.lvl === 4 && x.upHrdCloudCateSn === searchParam.category3 && x.stt === '00').map(x => {
      if (category.value.find(y => y.lvl === 5 && y.upHrdCloudCateSn === x.hrdCloudCateSn && y.stt === '00')) {
        x.hasSibling = true;
      } else {
        x.hasSibling = false;
      }
      return x;
    }));
    const category5 = computed(() => category.value.filter(x => x.lvl === 5 && x.upHrdCloudCateSn === searchParam.category4 && x.stt === '00'));


    const selectOptions = (hrdCloudCateSn, lvl) => {
      if (lvl === 1) {
        toggleLvl1.value.activate = !toggleLvl1.value.activate;
        searchParam.category2 = 0;
        searchParam.category3 = 0;
        searchParam.category4 = 0;
        searchParam.category5 = 0;
        toggleLvl2.value.hrdCloudCateSn = 0;
        toggleLvl3.value.hrdCloudCateSn = 0;
        toggleLvl4.value.hrdCloudCateSn = 0;
        toggleLvl5.value.hrdCloudCateSn = 0;

        searchParam.category1 = hrdCloudCateSn;
        toggleLvl1.value.hrdCloudCateSn = hrdCloudCateSn;
      } else if (lvl === 2) {
        searchParam.category3 = 0;
        searchParam.category4 = 0;
        searchParam.category5 = 0;
        toggleLvl3.value.hrdCloudCateSn = 0;
        toggleLvl4.value.hrdCloudCateSn = 0;
        toggleLvl5.value.hrdCloudCateSn = 0;

        searchParam.category2 = hrdCloudCateSn;
        toggleLvl2.value.hrdCloudCateSn = hrdCloudCateSn;
        if (category3.value.length === 0) emit('update:toggle',false);
      } else if (lvl === 3) {
        searchParam.category4 = 0;
        searchParam.category5 = 0;
        toggleLvl4.value.hrdCloudCateSn = 0;
        toggleLvl5.value.hrdCloudCateSn = 0;

        searchParam.category3 = hrdCloudCateSn;
        toggleLvl3.value.hrdCloudCateSn = hrdCloudCateSn;
        toggleLvl3.value.activate = true;

        if (category4.value.length === 0) emit('update:toggle',false);
      } else if (lvl === 4) {
        searchParam.category5 = 0;
        toggleLvl5.value.hrdCloudCateSn = 0;

        searchParam.category4 = hrdCloudCateSn;
        toggleLvl4.value.hrdCloudCateSn = hrdCloudCateSn;
        toggleLvl4.value.activate = true;

        if (category5.value.length === 0) emit('update:toggle',false);
      } else if (lvl === 5) {
        searchParam.category5 = hrdCloudCateSn;
        toggleLvl5.value.hrdCloudCateSn = hrdCloudCateSn;
        toggleLvl5.value.activate = true;
        emit('update:toggle',false);
      }
    };

    const setParamsByQuery = () => {
      if (route.name && route.name.indexOf('HrdCloud') > -1 && route.query) {
        initParams(toggleLvl1.value);
        initParams(toggleLvl2.value);
        initParams(toggleLvl3.value);
        initParams(toggleLvl4.value);
        initParams(toggleLvl5.value);
        if (route.query.category1) {
          category1.value = Number(route.query.category1);
          toggleLvl1.value.hrdCloudCateSn = category1.value;
        }
        if (route.query.category2) {
          toggleLvl2.value.hrdCloudCateSn = Number(route.query.category2);
          searchParam.category2 =Number(route.query.category2);
        }
        if (route.query.category3) {
          toggleLvl3.value.hrdCloudCateSn = Number(route.query.category3);
          searchParam.category3 =Number(route.query.category3);
        }
        if (route.query.category4) {
          toggleLvl4.value.hrdCloudCateSn = Number(route.query.category4);
          searchParam.category4 =Number(route.query.category4);
        }
        if (route.query.category5) {
          toggleLvl5.value.hrdCloudCateSn = Number(route.query.category5);
          searchParam.category5 =Number(route.query.category5);
        }
        if (route.query.pageNo) {
          searchParam.pageNo =Number(route.query.pageNo);
        }
      }
    };

    watch(() => route.query, () => {
      setParamsByQuery();
    },{deep:true});

    watch(() => searchParam, () => {
      pushQuery({
        ...route.query,
        pageNo: searchParam.pageNo,
        category2 : toggleLvl2.value.hrdCloudCateSn,
        category3 : toggleLvl3.value.hrdCloudCateSn,
        category4 : toggleLvl4.value.hrdCloudCateSn,
        category5 : toggleLvl5.value.hrdCloudCateSn,
      });
    }, {deep: true});

    watch(() => category1.value, () => {
      // console.log('category 2 : ',category2.value);
      // console.log('toggleLvl1 : ',toggleLvl1.value);
    });

    setParamsByQuery();

    return {
      category1, category2, category3, category4, category5,
      toggleLvl1, toggleLvl2, toggleLvl3, toggleLvl4, toggleLvl5,
      selectOptions
    }
  }
};
</script>