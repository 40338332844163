import store from '../../../../store';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
    ACT_DELETE_SOC_CMNT,
    ACT_INSERT_SOC_CMNT,
    ACT_UPDATE_SOC_CMNT,
    MUT_SHOW_BADGE,
} from '@/store/modules/ssl/ssl-index';
import {
    dateToDateFormat,
    isSuccess,
    timestampToDateFormat,
} from '@/assets/js/util';
import {
    limitSSL,
    viewCommentDelete,
    viewCommentEditConfirm,
    viewCommentUpdate,
} from '@/assets/js/modules/ssl/ssl-message';
import {useRoute, useRouter} from 'vue-router';

import {
    getDistCrseSns,
    getMyLrnTrgtGrp,
} from '@/assets/js/modules/ssl/ssl-common';
import {computed} from 'vue';
import {getBadgeList} from '@/assets/js/modules/ssl/ssl-badge';

const {showMessage, showConfirm} = useAlert();

const route = useRoute();
// 현재 학습분야

// 코멘트
// export const commentEditLimit = 1000 * 60 * 60 * 24 * 7;
// 당일만...
export const canCommentEdit = (regDt, isCollege) => {
    if(isCollege){
        return true;
    }
    return dateToDateFormat(new Date(), 'yyyyMMdd') === timestampToDateFormat(regDt, 'yyyyMMdd');
}

export const validateComment = (cmnt) => {
    if(!cmnt){
        showMessage('댓글 내용을 입력해주세요.');
        return false;
    }
    return true;
}

const insertComment = (isLoading, socLrnPostSn, upCmntSn, cmntLvl, cmnt, callback) => {
    if(isLoading) return;
    isLoading = true;
    store.dispatch(`ssl/${ACT_INSERT_SOC_CMNT}`, {socLrnPostSn, params: {cmnt:cmnt, upCmntSn:upCmntSn, cmntLvl:cmntLvl}}).then(res => {
        if(isSuccess(res) && res.socLrnCmntSn > 0){
            callback(res.socLrnCmntSn);

            // 뱃지 확인 하자
            if(res.badges && res.badges.length > 0){
                store.commit(`ssl/${MUT_SHOW_BADGE}`, res.badges);
                getBadgeList();
            }
        }
        isLoading = false;
    }).catch(() => {
        isLoading = false;
    });
}

const updateComment = (isLoading, comment) => {
    if(isLoading) return;
    isLoading = true;
    store.dispatch(`ssl/${ACT_UPDATE_SOC_CMNT}`, {socLrnCmntSn: comment.socLrnCmntSn, params: {cmnt: comment.cmnt}}).then(res => {
        if(isSuccess(res)){
            comment.editMode = false;
            showMessage(viewCommentUpdate);
        }
        isLoading = false;
    }).catch(() => {
        isLoading = false;
    });
}

const deleteComment = (isLoading, comments, idx) => {
    if(isLoading) return;
    isLoading = true;
    store.dispatch(`ssl/${ACT_DELETE_SOC_CMNT}`, comments[idx].socLrnCmntSn).then(res => {
        if(isSuccess(res)){
            showMessage(viewCommentDelete);
            comments.splice(idx, 1);
        }
        isLoading = false;
    }).catch(() => {
        isLoading = false;
    });
}

export const confirmInsertComment = (isDisabled, isLoading, socLrnPostSn, upCmntSn, cmntLvl, cmnt, callback) => {
    if(isDisabled) return;
    if(validateComment(cmnt)){
        showConfirm(viewCommentEditConfirm('등록'), () => {insertComment(isLoading, socLrnPostSn, upCmntSn, cmntLvl, cmnt, callback);});
    }
}

export const confirmUpdateComment = (canComment, isLoading, comment) => {
    if(!canComment) return;
    if(validateComment(comment.cmnt)){
        showConfirm(viewCommentEditConfirm('수정'), () => {updateComment(isLoading, comment);})
    }
}

export const confirmDeleteComment = (isLoading, comments, idx) => {
    showConfirm(viewCommentEditConfirm('삭제'), () => {deleteComment(isLoading, comments, idx)});
}

export const getRandomThumb = () => {
    const randomNo = parseInt(Math.random() * (30 - 1) + 1);
    return `/files/abstract_30/abstract_${randomNo}.jpg`
}

// export const isSSLLimit = () => {
//     const nowDate = dateToDateFormat(new Date(), 'yyyyMMddhhmm');
//     return (nowDate >= '202307010000' && nowDate < '202308280000')
//         || nowDate > '202311040000';
// }

export const isSSLPeriod = (dt, distCrseCd) => {
    const ndt = dt ? dt : new Date();
    const ndistCrseCd = distCrseCd ? distCrseCd : "all";


    const nowDate = dateToDateFormat(ndt, 'yyyyMMddhhmm');
    //
    if("trivia|live".indexOf(ndistCrseCd)>=0) {
        return (nowDate >= '202403250000' && nowDate < '202411020000')
            // 테스트 기간입니다.
            || (nowDate >= '202403060000' && nowDate < '202403242359');
    }else if("group".indexOf(ndistCrseCd)>=0) { //그룹러닝별도처리
        console.log(distCrseCd)
        return (nowDate >= '202403250000' && nowDate < '202406302359') ||
            (nowDate >= '202408260000' && nowDate < '202411020000') //
            // 테스트 기간입니다.
            || (nowDate >= '202403060000' && nowDate < '202403250000');
    }else if("book".indexOf(ndistCrseCd)>=0) {
        console.log(distCrseCd)
        return (nowDate >= '202406200000' && nowDate < '202408260000')
            // 테스트 기간입니다.
            || (nowDate >= '202403060000' && nowDate < '202403242359');
    }else {
        return (nowDate >= '202403250000' && nowDate < '202411020000')
            // 테스트 기간입니다.
            || (nowDate >= '202403060000' && nowDate < '202403242359');
    }
}


export const limitSave = (noMessage, istemp, dt, distCrseCd) => {
    // console.log("ccc", route);
    // let routerDistcrseCd = route.params.distCrseCd;
    // distCrseCd = distCrseCd ? distCrseCd : routerDistcrseCd;
    // ssl 기간이 아닐경우 학습노트 작성 또는 자동 임시저장을 할 수 없다.
    //저장버튼 클릭시 제한여부 확인하도록 현재 학습분야도 같이 넘겨야 함.
    const validate = !isSSLPeriod(dt, distCrseCd);

    // console.log("chk", distCrseCd, validate, noMessage);
    // if(validate && !noMessage) showMessage(halfNo > 1 ? limitSecondSSL : limitFirstSSL);
    if(validate && !noMessage) showMessage( limitSSL);
    return validate;
}

export const initTempSave = (tempSaveInterval, hasNoteChange, stt, socBoard, saveSocs) => {

    tempSaveInterval.value = setInterval(() => {
        if(limitSave(true, false,null, route.params.distCrseCd)){
            // 자동 저장 종료...
            clearInterval(tempSaveInterval.value);
            tempSaveInterval.value = null;
        }else{
            if(stt.value === '00'){
                clearInterval(tempSaveInterval.value);
                tempSaveInterval.value = null;
            }else{
                if(hasNoteChange.value){
                    if(!socBoard.title) socBoard.title = '피드 제목 입력';
                    saveSocs(true, true);
                    hasNoteChange.value = false;
                }
            }
        }
    }, 1000 * 60 * 5);
    // }, 1000 * 20);
}


export const writeRelSetup = (props, {emit}) => {
    const route = useRoute();
    const router = useRouter();
    const distCrseCd = computed(() => route.params.distCrseCd);
    const myLrnTrgtGrp = computed(() => getMyLrnTrgtGrp(getDistCrseSns(route.params.distCrseCd)));

    // group :: 0 => group 명, 1 => meeting 명, 3 => meeting Date
    // knowledge :: 0 => 학습자료 명명
    // hrd :: 0 => post image, 1 => hrd 이름, 3. hrd 분야
    // book :: 0 => book image, 1 => book 명
    const relInfos = computed(() => {
        if(props.refPost){
            return props.refPost.split('^');
        }
        return [];
    });

    // const currentRel = computed(() => sslRoutes[route.params.distCrseCd] ? sslRoutes[route.params.distCrseCd].rel.write : null);
    // const openLoading = ref(false);

    const clickDeleteRel = () => {
        showConfirm('연결 콘텐츠 정보를 삭제하시겠습니까?', () => {
            emit('update:refPost', null);
        });
    }

    const clickOpenRel = () => {
        // openRel(openLoading.value, distCrseCd.value, relInfos.value, props.refUrl, currentRel.value);
    }

    const goToHrd = item => {
        if (item && item.length > 3) {
            router.push({name: 'HrdCloudContentBridge', params: {lrnObjDtlDistSn: item[3], mode: 'detail'}});
        }
    }

    return {
        distCrseCd,
        myLrnTrgtGrp,
        relInfos,
        clickOpenRel,
        clickDeleteRel,
        goToHrd
    }
}
