import ApiService from '@/assets/js/api.service';

// ACTION
export const ACT_GET_HELP_CONTACT_LIST = 'actGetHelpContactList';

export const ACT_GET_HELP_NOTICE_LIST = 'actGetHelpNotificationList';
export const ACT_GET_HELP_NOTICE = 'actGetHelpNotification';
export const ACT_UPDATE_NOTICE_CNT = 'actUpdateNoticeCnt';
export const ACT_GET_LRN_NTC_LIST = 'actGetLrnNtcList';

export const ACT_GET_HELP_FAQ_LIST = 'actGetHelpFaqList';
export const ACT_GET_HELP_FAQ = 'actGetHelpFaq';

export const ACT_GET_HELP_QNA_LIST = 'actGetHelpQnaList';
export const ACT_GET_HELP_QNA = 'actGetHelpQna';
export const ACT_DELETE_HELP_QNA = 'actDeleteHelpQna';
export const ACT_UPDATE_HELP_QNA = 'actUpdateHelpQna';

export const ACT_GET_HELP_LIB_LIST = 'actGetHelpLibList';
export const ACT_GET_HELP_LIB = 'actGetHelpLib';

export const ACT_GET_HELP_FILE_ATCH_LIST = 'actGetHelpFileAtchList'
export const ACT_GET_CONTACT_LIST = 'actGetContactList';

const contactList = [
    {
        "title": "기획·운영팀",
        "member":[
            {
                "job_title": "기획·운영팀 총괄",
                "name": "박승호",
                "telno_view": 5371,
                "telno": "0220735371",
                "leaderdiv": "T"
            },
            {
                "job_title": "연수규정/지침, 성과관리, 직무인증제, 대내외 보고서",
                "name": "최승민",
                "telno_view": 5376,
                "telno": "0220735376",
                "leaderdiv": "M"
            },
            {
                "job_title": "연수규정/지침, 해외학술연수, 내부통제, 대내외 보고서",
                "name": "정유경",
                "telno_view": 5378,
                "telno": "0220735378",
                "leaderdiv": "M"
            },

            {
                "job_title": "연수규정/지침, 역량개발평가, 직무인증제, 부서요청교육",
                "name": "김지현",
                "telno_view": 7725,
                "telno": "0220737725",
                "leaderdiv": "M"
            },
            {
                "job_title": "임원연수(최고경영자과정, 하계포럼), MBA 과정",
                "name": "정은주",
                "telno_view": 5599,
                "telno": "0220735599",
                "leaderdiv": "M"
            },
            {
                "job_title": "리더십과정(Jump-up, Value-up(신임부점장/신임/승격예비/리마인드 과정), 전문직무직원 온보딩",
                "name": "김태언",
                "telno_view": 5367,
                "telno": "0220735367",
                "leaderdiv": "M"
            },
            {
                "job_title": "전문직무직원 채용/온보딩, 리더십과정(Jump-up, Value-up(신임부점장/신임/승격예비/리마인드 과정)",
                "name": "박재민",
                "telno_view": 8058,
                "telno": "0220738058",
                "leaderdiv": "M"
            },
            {
                "job_title": "리더십과정(Jump-up, Value-up(신임부점장/신임/승격예비/리마인드 과정)",
                "name": "김광준",
                "telno_view": 5387,
                "telno": "0220735387",
                "leaderdiv": "M"
            },
            {
                "job_title": "KB ACE Academy (기업금융/글로벌), 기업여신심사전문가, 기업금융 테마연수 , 통합법정교육",
                "name": "김영주",
                "telno_view": 5471,
                "telno": "0220735471",
                "leaderdiv": "M"
            },
            {
                "job_title": "KB ACE Academy (기업금융/글로벌), 기업여신심사전문가, 기업금융 테마연수 , 통합법정교육",
                "name": "오지은",
                "telno_view": 5374,
                "telno": "0220735374",
                "leaderdiv": "M"
            },
            {
                "job_title": "KB ACE Academy (DT기획/DT개발/IB/자본시장/리스크), DIGI Campus",
                "name": "구경민",
                "telno_view": 5364,
                "telno": "0220735364",
                "leaderdiv": "M"
            },
            {
                "job_title": "KB ACE Academy (DT기획/DT개발/IB/자본시장/리스크), DIGI Campus",
                "name": "송영주",
                "telno_view": 7854,
                "telno": "0220737854",
                "leaderdiv": "M"
            },
            {
                "job_title": "KB ACE Academy(자산관리), WM세일즈과정(ODS), 통신/사이버연수, 러닝라이브",
                "name": "김보은",
                "telno_view": 5474,
                "telno": "0220735474",
                "leaderdiv": "M"
            },
            {
                "job_title": "KB ACE Academy(자산관리), WM세일즈과정(ODS), 통신/사이버연수, 러닝라이브",
                "name": "최슬비",
                "telno_view": 7880,
                "telno": "0220737880",
                "leaderdiv": "M"
            },
            {
                "job_title": "SSL 프로그램, HRD클라우드, 자격증 취득과정 및 비용지원, 자기주도학습비 지원, 판매자격연수",
                "name": "권현진",
                "telno_view": 5357,
                "telno": "0220735357",
                "leaderdiv": "M"
            },
            {
                "job_title": "SSL 프로그램, HRD클라우드, 자격증 취득과정 및 비용지원, 자기주도학습비 지원, 판매자격연수",
                "name": "김보희",
                "telno_view": 5473,
                "telno": "0220735473",
                "leaderdiv": "M"
            },
            {
                "job_title": "연수원 운영/관리 총괄, 생활연수",
                "name": "박수진",
                "telno_view": 5233,
                "telno": "0220735233",
                "leaderdiv": "L"
            },
            {
                "job_title": "일산연수원 운영/대관 관리",
                "name": "이완중",
                "telno_view": 7858,
                "telno": "0220737858",
                "leaderdiv": "M"
            },
            {
                "job_title": "일산연수원 운영/대관 관리",
                "name": "서기원",
                "telno_view": 7852,
                "telno": "0220737852",
                "leaderdiv": "M"
            }]
    }   ,
    {
        "title":"채용·온보딩팀",
        "member":[
            {
                "job_title": "채용·온보딩팀 총괄",
                "name": "김우주",
                "telno_view": 5932,
                "telno": "0220735932",
                "leaderdiv": "L"
            },
            {
                "job_title": "신입행원, 경력직원(S), 계약직원 채용",
                "name": "조세훈",
                "telno_view": 4447,
                "telno": "0220734447",
                "leaderdiv": "M"
            },
            {
                "job_title": "신입행원, 경력직원(S), 계약직원 채용, 행내강사 양성 및 포상 프로그램",
                "name": "김유진",
                "telno_view": 8412,
                "telno": "0220738412",
                "leaderdiv": "M"
            },
            {
                "job_title": "전문직무직원 채용/계약관리/보상/운영/온보딩",
                "name": "박정선",
                "telno_view": 5356,
                "telno": "0220735356",
                "leaderdiv": "M"
            },
            {
                "job_title": "신입행원, 경력직원(S), 계약직원 채용",
                "name": "김민영",
                "telno_view": 8439,
                "telno": "0220738439",
                "leaderdiv": "M"
            },
            {
                "job_title": "교수(외환), 신입행원과정",
                "name": "장효선",
                "telno_view": 5472,
                "telno": "0220735472",
                "leaderdiv": "M"
            },
            {
                "job_title": "교수(기업여신), KB은행실무종합과정",
                "name": "전지호",
                "telno_view": 5363,
                "telno": "0220735363",
                "leaderdiv": "M"
            },
            {
                "job_title": "교수(가계여신), 신입행원 사후연수 온보딩 프로그램",
                "name": "정우용",
                "telno_view": 5366,
                "telno": "0220735366",
                "leaderdiv": "M"
            },
            ]
    }   ,
    {
        "title":"플랫폼팀",
        "member":[
            {
                "job_title": "연수플랫폼 개발/운영 총괄",
                "name": "김귀석",
                "telno_view": 5483,
                "telno": "0220735483",
                "leaderdiv": "T"
            },
            {
                "job_title": "스타런(연수시스템) 개발/운영, SSL, 자격증, 데이터인터페이스, 역량개발평가, DBA",
                "name": "최준원",
                "telno_view": 5482,
                "telno": "0220735482",
                "leaderdiv": "M"
            },
            {
                "job_title": "스타런(연수시스템) 개발/운영, 클라우드ON/NOW, 법정의무교육, 스튜디오 관리, 스타런앱",
                "name": "강우용",
                "telno_view": 5386,
                "telno": "0220735386",
                "leaderdiv": "M"
            },
            {
                "job_title": "스타런(연수시스템) 개발/운영, CP 인터페이스, CMS, 클라우드 인프라 관리, 연수원",
                "name": "유환주",
                "telno_view": 7744,
                "telno": "0220737744",
                "leaderdiv": "M"
            },
            {
                "job_title": "스타런(연수시스템) 개발/운영, 알림채널관리, 변경관리, 배포운영관리, CMS, 저작도구",
                "name": "조수빈",
                "telno_view": 5485,
                "telno": "0220735485",
                "leaderdiv": "M"
            }
            ]
    }
]

const state = {
    contactList : contactList
};

const mutations = {
};

const actions = {
    [ACT_GET_HELP_CONTACT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa030as', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HELP_NOTICE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/help/ntcs', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HELP_NOTICE](context, comNtcSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/help/ntcs', comNtcSn).then(response => resolve(response))
        });
    },
    [ACT_GET_LRN_NTC_LIST](context, params) {
        return new Promise(resolve => {
           ApiService.query('/v1/app/lrn/ntcs', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HELP_FAQ_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/help/faqs', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HELP_FAQ](context, comFaqSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/help/faqs', comFaqSn).then(response => resolve(response))
        });
    },
    [ACT_GET_HELP_QNA_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/help/qas', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HELP_QNA](context, comInqSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/help/qas', comInqSn).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_HELP_QNA](context, {comInqSn, params}) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/qa/${comInqSn}`, params).then(response => resolve(response))
        });
    },
    [ACT_DELETE_HELP_QNA](context, comInqSn) {
        return new Promise(resolve => {
            ApiService.delete('/v1/app/qa', comInqSn).then(response => resolve(response))
        });
    },
    [ACT_GET_HELP_LIB_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/help/boards', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HELP_LIB](context, comPostSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/help/boards', comPostSn).then(response => resolve(response))
        });
    },
    [ACT_GET_HELP_FILE_ATCH_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/help/files/atchs', params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_NOTICE_CNT](context, {comNtcSn}) {
        return new Promise(resolve => {
           ApiService.put(`/v1/app/help/ntc/${comNtcSn}/cnt`, null).then(response => resolve(response))
        });
    },
    [ACT_GET_CONTACT_LIST](){
        return contactList;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
