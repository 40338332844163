import ApiService from '@/assets/js/api.service';


export const ACT_GET_TASK_DIST_TY_LIST = 'getTaskDistTYList';
export const ACT_GET_TASK_SNDS_LIST = 'getTaskSndsList';
export const ACT_UPLOAD_TASK_ATCH_FILE = 'actUploadTaskAtchFile';
export const ACT_INSERT_TASK_REPORT_RSLTS = 'actInsertTaskReportRslt';


const state = {};

const mutations = {};

const actions = {

    [ACT_GET_TASK_DIST_TY_LIST](context, params){
        // params => list []
        return new Promise(resolve => {
            ApiService.query(`/v1/app/task/ty/list`, params).then(response => resolve(response));
        });
    },
    [ACT_GET_TASK_SNDS_LIST](context, params){
        // params => list []
        return new Promise(resolve => {
            ApiService.query(`/v1/app/task/snds`, params).then(response => resolve(response));
        });
    },
    [ACT_UPLOAD_TASK_ATCH_FILE](context, {params, files}) {
        return new Promise(resolve => {
            ApiService.upload('/v1/app/task/atch/file', files, params, false).then(response => resolve(response))});
    },
    [ACT_INSERT_TASK_REPORT_RSLTS](context, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/task/rslt', params).then(response => resolve(response));
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
