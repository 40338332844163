<template>
  <!-- [begin::popup-container] -->
  <div v-if="!isMobile" class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-facility-k10-2">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <form action="">
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">개인정보 마스킹</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">

            <div class="resv-container">
              <div class="resv-content">
                <!-- apply-container -->
                <div class="apply-container" style="padding: 0px; justify-content: center;">
                  <!-- apply-images -->
                  <div v-if="maskingImage != null" style="padding-right: 30px;">
                    <!--                    .lxp-layout .apply-container .apply-images .image-slide {width:540px;height:540px}-->
                    <!--                    .lxp-layout .apply-container .apply-images .image-slide img {width:100%;height:100%;object-fit:cover;}-->
                    <div v-for="(item,idx) in selectedItem" :key="idx" style="position: absolute;">

                      <svg v-if="isImgLoad" :style="`width: ${image.width}; height: ${image.height};`">
                        <path :d="`M ${item.boundingPoly.vertices[0].x * (image.width / image.naturalWidth) } ${item.boundingPoly.vertices[0].y * (image.height / image.naturalHeight)}
                        L ${item.boundingPoly.vertices[1].x * (image.width / image.naturalWidth)} ${item.boundingPoly.vertices[1].y * (image.height / image.naturalHeight)}
                        L ${item.boundingPoly.vertices[2].x * (image.width / image.naturalWidth)} ${item.boundingPoly.vertices[2].y * (image.height / image.naturalHeight)}
                        L ${item.boundingPoly.vertices[3].x * (image.width / image.naturalWidth)} ${item.boundingPoly.vertices[3].y * (image.height / image.naturalHeight)} Z`"
                              stroke="black" stroke-width="2" fill="black"></path>
                      </svg>
                    </div>

                    <div><img style="max-width: 800px;border:1px solid black" :src="maskingImage.src"
                              @load="onImageLoad" ref="image" :alt="maskingImage.title"/></div>
                  </div>
                  <!-- apply-content -->
                  <div class="apply-content">
                    <form @submit.prevent>
                      <!-- apply-form -->
                      <div>
                        <!-- apply-kb-form-field : 연수원 선택 -->
                        <div class="apply-kb-form-field">
                          <h4 class="field-title" style="text-align: center">개인정보 검출 대상 (클릭하여 마스킹 해제 가능)</h4>
                          <div class="kb-form-ocr-box-group">
                            <div v-for="(item,idx) in selectedValue" :key="idx" class="kb-form-box">

                              <input v-model="selectedItem" type="checkbox" class="kb-form-ocr-box-input"
                                     :name="`chk_01_${idx}`" :value="item" checked/>
                              <label :for="`chk_01_${idx}`" class="kb-form-ocr-box-label">{{ item.text }}</label>

                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div class="popup-buttons">
                      <button class="kb-btn kb-btn-primary kb-btn-lg" @click="confirm"><span class="text">적용</span>
                      </button>
                    </div>
                  </div>


                </div>

                <!-- //apply-container -->
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <div v-else>
    <div class="kb lxp-mobile-layout" id="kb" data-mode=""><!-- begin::kb-header -->
      <header class="kb-header" id="kb-header" style="border-bottom: 0px;">
        <div class="header-component"><!-- header-title -->
          <div class="header-column header-title" style="font-size: 20px;"> 개인정보 마스킹</div><!-- //header-title --><!-- header-util -->
        </div>
      </header><!-- end::kb-header --><!-- begin::kb-main -->
      <main class="kb-main" id="kb-facility"><!-- main-header -->
        <div class="main-header main-header-visual"></div><!-- //main-header --><!-- main-content -->
        <div class="main-content"><!-- content-section:연수원 -->
          <div v-if="maskingImage != null" style=" border-bottom: 1px solid var(--kb-light-silver)">
            <!--                    .lxp-layout .apply-container .apply-images .image-slide {width:540px;height:540px}-->
            <!--                    .lxp-layout .apply-container .apply-images .image-slide img {width:100%;height:100%;object-fit:cover;}-->
            <div v-for="(item,idx) in selectedItem" :key="idx" style="position: absolute;">

              <svg v-if="isImgLoad" :style="`width: ${image.width}; height: ${image.height};`">
                <path :d="`M ${item.boundingPoly.vertices[0].x * (image.width / image.naturalWidth) } ${item.boundingPoly.vertices[0].y * (image.height / image.naturalHeight)}
                        L ${item.boundingPoly.vertices[1].x * (image.width / image.naturalWidth)} ${item.boundingPoly.vertices[1].y * (image.height / image.naturalHeight)}
                        L ${item.boundingPoly.vertices[2].x * (image.width / image.naturalWidth)} ${item.boundingPoly.vertices[2].y * (image.height / image.naturalHeight)}
                        L ${item.boundingPoly.vertices[3].x * (image.width / image.naturalWidth)} ${item.boundingPoly.vertices[3].y * (image.height / image.naturalHeight)} Z`"
                      stroke="black" stroke-width="2" fill="black"></path>
              </svg>
            </div>

            <div><img style="width: 100%;" :src="maskingImage.src"
                      @load="onImageLoad" ref="image" :alt="maskingImage.title"/></div>
          </div>

          <div class="apply-content">
              <!-- apply-form -->
              <div>
                <!-- apply-kb-form-field : 연수원 선택 -->
                <div class="apply-kb-form-field">
                  <header class="kb-header" id="kb-header" style="border-bottom: 0px;">
                    <div class="header-component"><!-- header-title -->
                      <div class="header-column header-title" style="font-size: 15px;"> 개인정보 검출 대상 (클릭하여 마스킹 해제 가능)</div><!-- //header-title --><!-- header-util -->
                    </div>
                  </header>
                  <div class="kb-form-ocr-box-group">
                    <div v-for="(item,idx) in selectedValue" :key="idx" class="kb-form-box">

                      <input v-model="selectedItem" type="checkbox" class="kb-form-ocr-box-input"
                             :name="`chk_01_${idx}`" :value="item" checked/>
                      <label :for="`chk_01_${idx}`" class="kb-form-ocr-box-label">{{ item.text }}</label>

                    </div>
                  </div>
                </div>
              </div>

            <div class="popup-buttons" style="padding-top: 10px; text-align: center;">
              <button class="kb-btn kb-btn-primary kb-btn-lg" style="width:80px; height: 50px;" @click="confirm"><span class="text">적용</span>
              </button>
            </div>
          </div>

        </div><!-- //main-content --></main><!-- end::kb-main -->
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, onBeforeUnmount, reactive, ref} from "vue";
import {initParams, numberComma} from "@/assets/js/util";
import {ACT_GET_ACAD_GRD_LCSN, ACT_GET_LRN_COST_FLD} from "@/store/modules/support/support";
import {getListFunc} from "@/assets/js/ui.init";
import {useStore} from "vuex";

export default {
  name: "CheckOcrMaskingModal",
  components: {},
  props: {
    selectedValue: Object,
    ocrImageUrl: String
  },
  emits: ['update:modelValue', 'update:selectedValue'],
  setup(props, {emit}) {
    const isLoading = ref(false);
    const search = reactive({
      lrnCostDivSn: props.lrnCostDivSn,
      lrnCostFldNm: ''
    });
    console.log("selectedValue = ", props.selectedValue);
    const items = ref([]);
    const selectedItem = ref(props.selectedValue);
    const isImgLoad = ref(false);

    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    const image = ref(null);

    const paging = reactive({
      pageNo: 1,
      pageSize: 999999,
      totalCount: 0
    });

    const maskingImage = ref(
        {
          src: props.ocrImageUrl,
          title: '속초연수원',
        }
    );

    const confirm = () => {
      closeModal();
    };

    const closeModal = () => {
      initParams(search);
      emit('update:selectedValue', selectedItem.value);
      emit('update:modelValue', false);
    };

    const cbFunc = () => {
      isLoading.value = false;
    };

    const pagingFunc = async (pageNo) => {
      if (isLoading.value) return;
      isLoading.value = true;
      paging.pageNo = pageNo;

      if (search.lrnCostDivSn == '2') {
        getListFunc(`support/${ACT_GET_ACAD_GRD_LCSN}`, search, items, null, cbFunc, cbFunc);
      } else {
        getListFunc(`support/${ACT_GET_LRN_COST_FLD}`, search, items, null, cbFunc, cbFunc);
      }

    };

    const onImageLoad = () => {
      console.log("image.value = ", image.value);
      if (image.value) {
        isImgLoad.value = true;
      }
    }

    const handleEnter = (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      pagingFunc(1);
    };

    onBeforeMount(() => {
      //pagingFunc(1);
    });

    onBeforeUnmount(() => {
      initParams(search);
      // items.value = [];
    });

    return {
      isLoading, closeModal,
      search, items, selectedItem,
      paging, pagingFunc,
      handleEnter, confirm, numberComma,
      maskingImage,
      onImageLoad,
      image,
      isImgLoad,
      isMobile
    };
  }
}
</script>

<style scoped>

</style>