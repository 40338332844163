export const couponDays = [
  // ['20240213',  '20240214',   '20240215',   '20240216',   '20240219',   '20240220',   '20240221',   '20240222',   '20240223'],
  // ['20240226',  '20240227',   '20240228',   '20240229',   '20240304',   '20240305',   '20240306',   '20240307',   '20240308'],
  // ['20240311',  '20240312',   '20240313',   '20240314',   '20240315',   '20240318',   '20240319',   '20240320',   '20240321', '20240322'],
  ['20240325',  '20240326',   '20240327',   '20240328',   '20240329',   '20240401',   '20240402',   '20240403',   '20240404', '20240405'],
  ['20240408',  '20240409',   '20240411',   '20240412',   '20240415',   '20240416',   '20240417',   '20240418',   '20240419'],
  ['20240422',  '20240423',   '20240424',   '20240425',   '20240426',   '20240429',   '20240430',   '20240502',   '20240503'],
  ['20240507',  '20240508',   '20240509',   '20240510',   '20240513',   '20240514',   '20240516',   '20240517'],
  ['20240520',  '20240521',   '20240522',   '20240523',   '20240524',   '20240527',   '20240528',   '20240529',   '20240530',  '20240531'],
  ['20240603',  '20240604',   '20240605',   '20240607',   '20240610',   '20240611',   '20240612',   '20240613',   '20240614'],
  ['20240617',  '20240618',   '20240619',   '20240620',   '20240621',   '20240624',   '20240625',   '20240626',   '20240627',  '20240628'],
  ['20240701',  '20240702',   '20240703',   '20240704',   '20240705',   '20240708',   '20240709',   '20240710',   '20240711',  '20240712'],
  ['20240715',  '20240716',   '20240717',   '20240718',   '20240719',   '20240722',   '20240723',   '20240724',   '20240725',  '20240726'],
  ['20240729',  '20240730',   '20240731',   '20240801',   '20240802',   '20240805',   '20240806',   '20240807',   '20240808',  '20240809'],
  ['20240812',  '20240813',   '20240814',   '20240816',   '20240819',   '20240820',   '20240821',   '20240822',   '20240823'],
  ['20240826',  '20240827',   '20240828',   '20240829',   '20240830',   '20240902',   '20240903',   '20240904',   '20240905',  '20240906'],
  ['20240909',  '20240910',   '20240911',   '20240912',   '20240913',   '20240919',   '20240920'],
  ['20240923',  '20240924',   '20240925',   '20240926',   '20240927',   '20240930',   '20241002',   '20241004'],
  ['20241007',  '20241008',   '20241010',   '20241011',   '20241014',   '20241015',   '20241016',   '20241017',   '20241018'],
  ['20241021',  '20241022',   '20241023',   '20241024',   '20241025',   '20241028',   '20241029',   '20241030',   '20241031',  '20241101']

];

export const HrdCloudCouponDays = [
  ['20240201',  '20240202',   '20240205',   '20240206',   '20240207',   '20240208'],
  ['20240213',  '20240214',   '20240215',   '20240216',   '20240219',   '20240220',   '20240221',   '20240222',   '20240223'],
  ['20240226',  '20240227',   '20240228',   '20240229',   '20240304',   '20240305',   '20240306',   '20240307',   '20240308'],
  ['20240311',  '20240312',   '20240313',   '20240314',   '20240315',   '20240318',   '20240319',   '20240320',   '20240321', '20240322'],
  ['20240325',  '20240326',   '20240327',   '20240328',   '20240329',   '20240401',   '20240402',   '20240403',   '20240404', '20240405'],
  ['20240408',  '20240409',   '20240411',   '20240412',   '20240415',   '20240416',   '20240417',   '20240418',   '20240419'],
  ['20240422',  '20240423',   '20240424',   '20240425',   '20240426',   '20240429',   '20240430',   '20240502',   '20240503'],
  ['20240507',  '20240508',   '20240509',   '20240510',   '20240513',   '20240514',   '20240516',   '20240517'],
  ['20240520',  '20240521',   '20240522',   '20240523',   '20240524',   '20240527',   '20240528',   '20240529',   '20240530',  '20240531'],
  ['20240603',  '20240604',   '20240605',   '20240607',   '20240610',   '20240611',   '20240612',   '20240613',   '20240614'],
  ['20240617',  '20240618',   '20240619',   '20240620',   '20240621',   '20240624',   '20240625',   '20240626',   '20240627',  '20240628'],
  ['20240701',  '20240702',   '20240703',   '20240704',   '20240705',   '20240708',   '20240709',   '20240710',   '20240711',  '20240712'],
  ['20240715',  '20240716',   '20240717',   '20240718',   '20240719',   '20240722',   '20240723',   '20240724',   '20240725',  '20240726'],
  ['20240729',  '20240730',   '20240731',   '20240801',   '20240802',   '20240805',   '20240806',   '20240807',   '20240808',  '20240809'],
  ['20240812',  '20240813',   '20240814',   '20240816',   '20240819',   '20240820',   '20240821',   '20240822',   '20240823'],
  ['20240826',  '20240827',   '20240828',   '20240829',   '20240830',   '20240902',   '20240903',   '20240904',   '20240905',  '20240906'],
  ['20240909',  '20240910',   '20240911',   '20240912',   '20240913',   '20240919',   '20240920'],
  ['20240923',  '20240924',   '20240925',   '20240926',   '20240927',   '20240930',   '20241002',   '20241004'],
  ['20241007',  '20241008',   '20241010',   '20241011',   '20241014',   '20241015',   '20241016',   '20241017',   '20241018'],
  ['20241021',  '20241022',   '20241023',   '20241024',   '20241025',   '20241028',   '20241029',   '20241030',   '20241031',  '20241101'],
  ['20241104',  '20241105',   '20241106',   '20241107',   '20241108',   '20241111',   '20241112',   '20241113',   '20241114',  '20241115'],
  ['20241118',  '20241119',   '20241120',   '20241121',   '20241122',   '20241125',   '20241126',   '20241127',   '20241128',  '20241129']

];