import {getToken} from "@/assets/js/localstorage.service";
import {TOKEN_KEY} from "@/store/modules/auth/auth";

export default class UploadAdapter {
    constructor( loader, url ) {
        // The file loader instance to use during the upload.
        this.loader = loader;
        this.url = url;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then(file => new Promise( ( resolve, reject ) => {
                this._initRequest();
                this._initListeners( resolve, reject, file );
                this._sendRequest(file);
            }));
    }

    // Aborts the upload process.
    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        // 임시 지정임...
        xhr.open( 'POST', this.url ? this.url : '/v1/app/socs/editor/upload', true );
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.setRequestHeader(TOKEN_KEY, getToken(TOKEN_KEY));
        xhr.responseType = 'json';
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners( resolve, reject, file ) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${ file.name }.`;

        xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        xhr.addEventListener( 'abort', () => reject() );
        xhr.addEventListener( 'load', () => {
            const response = xhr.response;

            if (!response || response.error) {
                return reject( response && response.error ? response.error.message : genericErrorText );
            }

            if (response.fields && response.fields.length > 0) {
                resolve({fields: response.fields, default: response.url, url: response.url});
            } else {
                resolve({default: response.url});
            }
        });

        if (xhr.upload) {
            xhr.upload.addEventListener( 'progress', evt => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    // Prepares the data and sends the request.
    _sendRequest( file ) {
        // Prepare the form data.
        const data = new FormData();
        data.append( 'uploadFile', file );
        this.xhr.send( data );
    }
}
