<template>
  <div class="kb lxp-layout" :class="{'home' : isMain}" id="kb" :data-bg="dataBg">
    <vue-progress-bar/>
    <LxpHeader :effect-nav="touchBodyCount" :func="plusEffectNav" />
    <div @click="plusEffectNav">
      <router-view />
      <HrdCloudPopups v-if="$route.name && ($route.name.indexOf('Hrd') > -1 || $route.name.indexOf('Group') > -1||$route.name.indexOf('BadgeBox') > -1)"/>
      <!--      <DailyPopup v-if="(($route.path && ($route.path.indexOf('/daily') > -1 && $route.path !== '/daily/pearl')) && !expirationDaliyPopup)"/>-->
      <!--      <DailyPopup v-if="(($route.name === 'DailyApply') && !expirationDaliyPopup)"/>-->
      <NotiAlertBar v-if="$route.name !== 'TrainNotiView'" />
      <div class="floating-btn-area d-print-none">
        <SSLWriteButton v-if="!hideSSLBtn" :effect-nav="touchBodyCount" :effect-func="plusEffectNav" />
      </div>
    </div>
    <LxpFooter/>
    <LxpPopup/>
  </div>
</template>
<script>
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {computed, onMounted, onUnmounted, ref, watch} from 'vue';
import LxpHeader from '@/views/layout/lxp/header/LxpHeader';
import LxpFooter from '@/views/layout/lxp/footer/LxpFooter';
import {getCheckItems, getItems, initProgressBar, lengthCheck} from '@/assets/js/util';
import {
  ACT_GET_CODE_LIST,
  ACT_GET_MEDIA_CATE_LIST,
  MUT_SET_CATE_LIST,
  MUT_SET_CODE_LIST,
} from '@/store/modules/code/code';
import NotiAlertBar from '@/components/common/NotiAlertBar';
import LxpPopup from '@/components/common/LxpPopup';
import HrdCloudPopups from '@/components/common/HrdCloudPopups';
import SSLWriteButton from "@/components/ssl/SSLWriteButton.vue";
import {getHrdCates} from '@/assets/js/modules/hrd/hrd-common';
import {setProfileImgUrl} from '@/assets/js/modules/common/common';
import {setHotTipState} from "@/assets/js/modules/learn/learn-hot-tip";
import {MUT_INIT_BOARD_MST_OBJECT} from "@/store/modules/board/board-mst";

export default {
  name: 'LxpLayout',
  components: {
    HrdCloudPopups,
    LxpPopup,
    NotiAlertBar,
    LxpFooter,
    LxpHeader,
    SSLWriteButton,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const touchBodyCount = ref(0);
    const concentrationMode = ref('');

    const fillNames = ['MentoringWrite' , 'SSMWrite', 'LearnBoardWrite'];
    const session = computed(() => store.state.auth.session);
    const dataBg = computed(() => {
      if(fillNames.includes(route.name)) return 'fill';
      return concentrationMode.value;
    });

    let nowDate = new Date();
    const currentTs = new Date().getTime();
    nowDate = nowDate.setTime(currentTs);
    // 현재 시각과 스토리지에 저장된 시각을 각각 비교하여
    // 시간이 남아 있으면 true, 아니면 false 리턴
    const expirationDaliyPopup = computed(() => parseInt(localStorage.getItem('kb-popup-daliy')) > nowDate);
    const hideSSLBtn = computed(() => {
      //hottip 게시물 조회시 ssl 버튼 활성화
      if(route.path.indexOf("/board/hottip-")>-1) return false;
      return session.value.jbpsCd === 'A99' || route.path.indexOf('/hottip') > -1
    });

    const plusEffectNav = () => {
      touchBodyCount.value++;
    };

    setProfileImgUrl() // profile img url set

    const isMain = computed(() => route.name === 'Main');

    const getCodes = () => {
      // if (codeExpiredTime.value < new Date().getTime()) {
      store.dispatch(`code/${ACT_GET_CODE_LIST}`).then(res => {
        if (lengthCheck(res)) {
          store.commit(`code/${MUT_SET_CODE_LIST}`, getItems(res));
        }
      });
      // }
    };

    const getCates = () => {
      store.dispatch(`code/${ACT_GET_MEDIA_CATE_LIST}`, {treeYn: 'Y'}).then(res => {
        store.commit(`code/${MUT_SET_CATE_LIST}`, getCheckItems(res));
      });
    };

    watch(() => store.state.hrdcloud.mode, ()=>{
      if (store.state.hrdcloud.mode === 'dark') {
        concentrationMode.value = 'dark';
      } else if (store.state.hrdcloud.mode === 'fill') {
        concentrationMode.value = 'fill';
      } else {
        concentrationMode.value = '';
      }
    });

    watch(() => route.path, () => {
      initProgressBar();
    });

    getCodes();
    getCates();
    getHrdCates();
    initProgressBar();

    onMounted(setHotTipState)

    onUnmounted(() => {
      store.commit(`boardmst/${MUT_INIT_BOARD_MST_OBJECT}`);
    })

    return {
      isMain,
      dataBg,
      plusEffectNav,
      touchBodyCount,
      expirationDaliyPopup,
      session,
      hideSSLBtn,
    }
  }
}
</script>
