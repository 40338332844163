<template>
  <HrdCloudMobileHeader v-if="header.show" :show-back="true">
    <template v-slot:right>
      <HrdCloudAtchFile v-if="!isLoading && hrd" :is-mobile="isMobile" :hrd="hrd" />
    </template>
  </HrdCloudMobileHeader>
  <main class="kb-main" id="kb-myclass">
    <div class="main-content main-component">
      <HrdCloudMobileMode />
      <div class="hrdcloud-view-container">
        <div class="player hrdcloud-player">
          <div class="player-container">
            <div class="player-inner">
              <LearnHrdPdf
                  v-if="!isLoading && hrd"
                  :cms="hrd"
                  v-model:is-series-active="isSeriesActive"
              />
            </div>
            <div class="player-details">
              <HrdMobilePlayerDetails v-if="hrd" :hrd="hrd" :funcs="detailFuncs" />
            </div>
          </div>
        </div>
        <HrdCloudMobileSeriesList v-if="isSeriesActive && hrd && hrd.cttSnLst != null && hrd.cttSnLst.length > 0" :crse="hrd" />
        <HrdCloudMobileComment v-if="hrd" :effect-nav="effectNav" :objSn="hrd.objSn" />
      </div>
    </div>
  </main>
  <div v-if="hrd" class="popup-container p-0 d-block" id="kb-mobile-popup" :class="{ 'is-active' : showModal }">
    <HrdCloudMobileHistory v-model="historyModal" :title="hrd.objNm" :duration="hrd.cttSz" :items="histories" :lrnObjDtlDistSn="lrnObjDtlDistSn" :ctt-ty-cd-dcd="hrd.cttTyCdDcd" />
    <HrdCloudMobileShare v-if="shareModal" v-model="shareModal" :data="hrd" />
    <HrdCloudMobileOpinion v-model="opinionModal" :cttMstSn="hrd.objSn" :title="hrd.objNm"/>
  </div>
</template>
<script>
import {goBack} from '@/assets/js/modules/common/common';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {getItem, initProgressBar, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/util';
// import {ACT_GET_HRDCLOUD_DETAIL} from '@/store/modules/prep/prep';
import {
  darkMode,
  detailCategoryDisplay,
  disableMyAddChanged,
  generateUUID,
  getUserProgress,
  handleActions,
  lightMode,
  pageView,
  toggleConcentrationMode,
} from '@/assets/js/modules/hrd/hrd-common';
import {lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import LearnHrdPdf from '@/components/learn/window/LearnHrdPdf';
import HrdCloudMobileShare from '@/components/hrdcloud/mobile/HrdCloudMobileShare';
import HrdCloudMobileOpinion from '@/components/hrdcloud/mobile/HrdCloudMobileOpinion';
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import HrdCloudAtchFile from '@/components/hrdcloud/HrdCloudAtchFile';
import HrdCloudMobileComment from '@/components/hrdcloud/mobile/HrdCloudMobileComment';
import HrdMobilePlayerDetails from '@/components/hrdcloud/mobile/HrdMobilePlayerDetails';
import HrdCloudMobileMode from '@/components/hrdcloud/mobile/HrdCloudMobileMode.vue';
import HrdCloudMobileHistory from '@/components/hrdcloud/mobile/HrdCloudMobileHistory.vue';
import HrdCloudMobileSeriesList from "@/components/hrdcloud/mobile/HrdCloudMobileSeriesList.vue";

export default {
  name: 'HrdMobilePdf',
  components:{
    HrdCloudMobileHistory,
    HrdCloudMobileMode,
    HrdMobilePlayerDetails,
    HrdCloudMobileComment,
    HrdCloudAtchFile,
    HrdCloudMobileHeader,
    HrdCloudMobileOpinion,
    HrdCloudMobileShare,
    LearnHrdPdf,
    HrdCloudMobileSeriesList
  },
  props:{
    objDetail: Object,
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const store = useStore(), route = useRoute();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    const hrd = computed(() => props.objDetail);
    // const {showMessage} = useAlert();
    const isLoading = ref(true);
    const category = ref({});
    const concentrationModeText = computed(() => store.state.hrdcloud.mode === 'dark' ? '집중모드 켜짐' : '집중모드 꺼짐');
    const isMenuActive = ref(false);
    const user = reactive({ progRsltSn: 0, maxPlaySec: 0, lastPlaySec: 0, cmptnYn: 'N', mdfcnDt: null, regDt: null });
    const showModal = ref(false), historyModal = ref(false), shareModal = ref(false), opinionModal = ref(false);
    const histories = ref([]);
    const mainShow = computed(() => store.state.hrdcloud.main.show);
    const isSeriesActive = ref(false);

    const header = reactive({
      show: true,
      toggle: (tog) => {
        header.show = tog
      },
    });

    const objectParam = computed(() => {
      return {
        distCrseSn: hrd.value.distCrseSn,
        distChapSn: hrd.value.distChapSn,
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn,
        objSn: hrd.value.objSn,
        // division: 'vod'
      }
    });

    // 콘텐츠 정보
    const getHrdCloudDetail = async () => {


      pageView(hrd);
      await getUUID();
      const userProgressRes = await getUserProgress(hrd);
      if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      isLoading.value = false;


      // const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value})
      // if (lengthCheck(res)) {
      //   hrd.value = res.items.map(x => {
      //     return {
      //       ...getTop1CategoryByLevel(x.cloudKeyLst),
      //       ...x,
      //       tag: makeTags(x.tag)
      //     };
      //   })[0];
      //   pageView(hrd);
      //   await getUUID();
      //   const userProgressRes = await getUserProgress(hrd);
      //   if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      //   isLoading.value = false;
      // } else {
      //   isLoading.value = false;
      //   showMessage("데이터 조회중 오류가 발생했습니다.");
      // }
    };

    const togglePlayerMenu = () => {
      isMenuActive.value = !isMenuActive.value;
    };

    const detailFuncs = {
      openHistory: (item) => {
        handleActions(item).actHistory(historyModal, histories);
      },
      openShare: (item) => {
        handleActions(item).actShare(shareModal);
      },
      openOpinion: (item) => {
        handleActions(item).actOpinion(opinionModal);
      }
    };

    const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);

    // 담기 후 업데이트를 위해 한번 더 호출
    watch(() => leaderAdd.value.show, () => {
      if (hrd.value && !leaderAdd.value.show && leaderAdd.value.updateMyAdd) {
        const lrnObjDtlDistSn = leaderAdd.value.lrnObjDtlDistSn;
        if (hrd.value.lrnObjDtlDistSn === lrnObjDtlDistSn) {
          hrd.value.put = leaderAdd.value.isPut;
          disableMyAddChanged();
        }
      }
    });

    const uuid = ref(null);
    const getUUID = async () => {
      let id = await generateUUID();
      if (!uuid.value) {
        id = await generateUUID();
        uuid.value = id.uuid;
      }
    };

    getHrdCloudDetail();

    watch(() => lrnObjDtlDistSn.value, () => {
      initProgressBar();
      if (lrnObjDtlDistSn.value > 0) {
        getHrdCloudDetail();
      }
    });

    watch(() => historyModal.value, () => {
      showModal.value = !showModal.value;
    });
    watch(() => shareModal.value, () => {
      showModal.value = !showModal.value;
    });
    watch(() => opinionModal.value, () => {
      showModal.value = !showModal.value;
    });

    onMounted(() => {
      darkMode();
    });

    onBeforeUnmount(() => {
      lightMode();
    });


    return {
      isMobile, session, uuid, lrnObjDtlDistSn,
      hrd, category, user, histories,
      goBack,
      concentrationModeText,
      isMenuActive,
      toggleConcentrationMode,
      togglePlayerMenu,
      timestampToDateFormat,
      handleActions, detailFuncs, detailCategoryDisplay,
      historyModal, shareModal, opinionModal, objectParam,
      lrnObjTyCds, showModal, header, mainShow,
      isLoading,
      isSeriesActive
    };
  }
};
</script>