import {
  ACT_GET_ADDR_LINK_LIST,
  ACT_GET_UTIL_UUID,
  ACT_INSERT_FILE_ATCH,
  ACT_GET_FILE_DOWN_MNG,
  ACT_GET_FILE_DOWN_HIST_SN,
  ACT_DOWNLOAD_FILE_DOWN_ATCH,
  ACT_INSERT_QA,
  MUT_CLOSE_CONFIRM,
  MUT_CLOSE_CUSTOM_CONFIRM,
  MUT_CLOSE_MESSAGE,
  MUT_DISABLE_NOSLEEP,
  MUT_ENABLE_NOSLEEP,
  MUT_HIDE_FOOTER,
  MUT_HIDE_LOADING,
  MUT_SET_FROM_QUERY,
  MUT_SET_NOSLEEP,
  MUT_SET_RETURN_QUERY,
  MUT_SHOW_CONFIRM,
  MUT_SHOW_CUSTOM_CONFIRM,
  MUT_SHOW_FOOTER,
  MUT_SHOW_LOADING,
  MUT_SHOW_MESSAGE,
  MUT_SHOW_TOAST,
} from '@/store/modules/common/common-index';
import ApiService from '@/assets/js/api.service';

const state = {
  fromQuery: null,
  returnQuery: null,
  toast: {
    show: false,
    text: '',
    timeout: null
  },
  msg: {
    show: false,
    title: '',
    text: '',
    center: false,
    callback: null
  },
  confirm: {
    show: false,
    title: '',
    text: '',
    okBtnTitle: '확인',
    callback: null,
    cancelCallback: null,
    closeCallback: null
  },
  customConfirm: {
    show: false,
    title: '',
    text: '',
    firstBtnTitle: '확인',
    secondBtnTitle: '확인',
    firstCallback: null,
    secondCallback: null,
    cancelCallback: null
  },
  loading: false,
  footer: {
    hide: false,
  },
  noSleep: {
    instance: null,
    enabled: false
  }
};

const mutations = {
  [MUT_SET_FROM_QUERY](state, query) {
    state.fromQuery = query;
  },
  [MUT_SET_RETURN_QUERY](state, query) {
    state.returnQuery = query;
  },
  [MUT_SHOW_TOAST](state, text) {
    state.toast.show = true;
    state.toast.text = text;
    // 3초
    if(state.toast.timeout != null) {
      clearTimeout(state.toast.timeout);
      state.toast.timeout = null;
    }
    state.toast.timeout = setTimeout(() => {
      state.toast.show = false;
    }, 5000);
  },
  [MUT_SHOW_MESSAGE](state, msg) {
    if(msg instanceof Object){
      state.msg.title = msg.title;
      state.msg.text = msg.text;
      state.msg.center = !!msg.center;
      state.msg.callback = msg.callback;
    }else{
      state.msg.title = '';
      state.msg.text = msg;
      state.msg.callback = null;
    }
    state.msg.show = true;
  },
  [MUT_CLOSE_MESSAGE](state) {
    state.msg.show = false;
  },
  [MUT_SHOW_CONFIRM](state, {text, title, okBtnTitle, callback, closeCallback, cancelCallback}) {
    state.confirm.text = text;
    state.confirm.title = title;
    state.confirm.okBtnTitle = okBtnTitle || '확인';
    state.confirm.callback = callback;
    state.confirm.closeCallback = closeCallback;
    state.confirm.cancelCallback = cancelCallback;
    state.confirm.show = true;
  },
  [MUT_CLOSE_CONFIRM](state) {
    state.confirm.show = false;
  },
  [MUT_SHOW_LOADING](state, text) {
    state.loading = true;
    state.msg.text = text;
  },
  [MUT_HIDE_LOADING](state) {
    state.loading = false;
  },
  [MUT_SHOW_FOOTER](state) {
    state.footer.hide = false;
  },
  [MUT_HIDE_FOOTER](state) {
    state.footer.hide = true;
  },
  [MUT_SHOW_CUSTOM_CONFIRM](state, {text, title, firstBtnTitle, secondBtnTitle, firstCallback, secondCallback, cancelCallback}) {
    state.customConfirm.text = text;
    state.customConfirm.title = title;
    state.customConfirm.firstBtnTitle = firstBtnTitle || '확인';
    state.customConfirm.secondBtnTitle = secondBtnTitle || '확인';
    state.customConfirm.firstCallback = firstCallback;
    state.customConfirm.secondCallback = secondCallback;
    state.customConfirm.cancelCallback = cancelCallback;
    state.customConfirm.show = true;
  },
  [MUT_CLOSE_CUSTOM_CONFIRM](state) {
    state.customConfirm.show = false;
  },
  [MUT_SET_NOSLEEP](state, obj) {
    state.noSleep.instance = obj;
  },
  [MUT_ENABLE_NOSLEEP](state) {
    if (state.noSleep.instance) state.noSleep.instance.enable();
  },
  [MUT_DISABLE_NOSLEEP](state) {
    if (state.noSleep.instance) state.noSleep.instance.disable();
  }
};

const actions = {
  [ACT_GET_UTIL_UUID](context, params) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/util/uuid`, params).then(response => resolve(response))
    });
  },
  [ACT_INSERT_QA](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/qa', params).then(response => resolve(response));
    });
  },
  [ACT_INSERT_FILE_ATCH](context, {params, files}) {
    return new Promise(resolve => {
      ApiService.upload('/v1/app/file', files, params, true).then(response => resolve(response))});
  },
  [ACT_GET_FILE_DOWN_MNG](context, fileDownId) {
    return new Promise(resolve => {
      ApiService.get(`/v1/app/filedown/mng`, fileDownId).then(response => resolve(response))
    });
  },
  [ACT_GET_FILE_DOWN_HIST_SN](context, parmas) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/filedown/hist`, parmas).then(response => resolve(response))
    });
  },
  [ACT_DOWNLOAD_FILE_DOWN_ATCH](context, {fileDownId, fileName}) {
    ApiService.download('/v1/app/filedown/atch', fileDownId, {}, fileName);
  },
  [ACT_GET_ADDR_LINK_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/addr/link', params).then(response => resolve(response))
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
