<template>
  <div class="layer-container" style="width:640px;height:394px;background-color:unset;"
       :style="{ top:`${randPos.top}px !important`, left:`${randPos.left}px !important` }"
       :class="{ 'is-active' : toggle.coldCall }"

  >
    <div ref="layerCont" class="layer" style="cursor: pointer;" :style="{background:successColor}" @click="handleRespond">
      <div class="layer-contents">
        <div class="layer-icon"><img src="../../assets/lxp/images/icon/icon_coldcall.svg" alt="" /></div>
        <template v-if="toggle.coldCallSuccess">
          <strong class="layer-title">클릭 성공!</strong>
          <div class="layer-content">
            <p class="text">학습을 계속 진행해주세요.</p>
          </div>
        </template>
        <template v-else>
          <strong class="layer-title">잠깐!</strong>
          <div class="layer-content">
            <p class="text">학습중이라면 클릭해주세요.</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {hrdColdCallSetup} from '@/assets/js/modules/hrd/hrd-coldcall-setup';

export default {
  name: 'HrdCloudColdCall',
  props: {
    playerCont: Object,
    player: Object,
    uuid: String,
    progress: Object,
    objectParam: Object,
    meta: Object,
    lastPlaySec: {
      type:Number,
      default: 0
    },
    popups: Object,
    completeActive: Boolean
  },
  emits:['update:completeActive'],
  setup: hrdColdCallSetup
};
</script>

<style scoped>
  .border{
    cursor: pointer;
    border: 1px solid var(--ssl-silver);
    border-radius: 8px;
  }
</style>