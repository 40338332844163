<template>
  <div class="editor-container">
    <div class="editor-aside">
      <div class="editor-aside-inner">
        <div class="aside-feed-preface">
          <div class="kb-form-thumbnail">
            <img v-if="socBoard.thumb" class="kb-form-file-label" :src="socBoard.thumb" alt="" @click="showUploadModal">
            <label v-else class="kb-form-file-label" @click="showUploadModal"></label>
          </div>
          <div class="kb-form-title">
            <textarea v-model.trim="socBoard.title" cols="30" rows="10" placeholder="피드 제목 입력"></textarea>
          </div>
          <div class="kb-form-tags">
            <div class="tag-list">
              <div v-for="(hashTag, idx) in hashTags" class="tag-item is-active" :key="idx">
                <span class="tag-item-text">{{ hashTag }}</span>
                <button class="kb-btn-tag" @click="deleteHashTag(idx)">
                  <i class="icon-tag-delete"></i>
                </button>
              </div>
              <div class="tag-item">
                <input v-model.trim="hashTag" type="text" class="tag-item-input" placeholder="태그"
                       @keyup.enter="addHashTag"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="aside-feed-buttons">
          <button v-if="stt === '' || stt === '01'" class="kb-btn kb-btn-light-gray" @click="clickSaveButton(true)">
            <span class="text">임시저장</span>
          </button>
          <button class="kb-btn kb-btn-light-gray" @click="togglePreviewVisible(true)">
            <span class="text">미리보기</span>
          </button>
          <button class="kb-btn kb-btn-dark" @click="clickSaveButton(false)">
            <span class="text">{{ stt === '00' ? '수정' : '등록'}}</span>
          </button>
        </div>

        <div class="aside-bottom-buttons">
          <button class="kb-btn kb-btn-light-gray" @click="exitSocWrite">
            <span class="text">나가기</span>
          </button>
          <button v-if="socLrnPostSn > 0 && stt === '01'" class="kb-btn btn btn-warning" @click="clickDeleteButton">
            <span class="text">삭제</span>
          </button>
        </div>
      </div>
    </div>

    <div class="editor-content">
      <div class="editor-content-body">
        <div v-show="false" id="editorTextarea" ref="pageEditor"></div>
        <iframe v-if="isInApp" ref="editorIframe" width="100%" height="100%"></iframe>
      </div>
      <div class="editor-content-footer">
        <SSLWriteRel
            :socLrnPostSn="Number(socLrnPostSn)"
            v-model:refPost="socBoard.refPost"
            v-model:refUrl="socBoard.refUrl"
        />
        <div class="content-byte">
          <span class="current">{{ textCount }}</span>
          <span class="total">{{ maxTextLen }}</span>
        </div>
      </div>
    </div>
    <!-- //editor-aside -->

    <SSLUploadModal :is-visible="uploadModal" :close-func="closeThumbnail" :apply-func="applyPCThumbnail" :init-effect="uploadEffect"/>

    <SSLPreview
        :is-visible="previewVisible"
        :toggle-visible="togglePreviewVisible"
        :soc-board="socBoard"
        :current-page-idx="currentPageIdx"
        :soc-pages="socPages"
        :hash-tags="hashTags"
    />

    <CheckOcrMaskingModal
        v-if="showOcrMaskingModal"
        v-model="showOcrMaskingModal"
        v-model:selected-value="textInfo"
        v-model:ocrImageUrl="ocrImageUrl"
    />

  </div>
</template>
<script>
import SSLUploadModal from '@/components/ssl/write/SSLUploadModal';
import SSLPreview from '@/components/ssl/write/SSLPreview';
import SSLWriteRel from '@/components/ssl/write/SSLWriteRel';
import {sslWriteSetup} from '@/assets/js/modules/ssl/setup/ssl-write-setup';
import CheckOcrMaskingModal from "@/components/support/CheckOcrMaskingModal";

export default {
  name: 'SSLWrite',
  components: {
    CheckOcrMaskingModal,
    SSLWriteRel,
    SSLPreview,
    SSLUploadModal,
  },
  setup: sslWriteSetup
}
</script>

<style>

.lxp-layout.ssl-layout #ssl-editor .ck-editor__editable {  //margin-top:40px !important;
  overflow: scroll;
  overflow-x: hidden;
  height: 766px !important;
}

.lxp-layout.ssl-layout #ssl-editor .ck-editor__editable::-webkit-scrollbar {
  width:10px;
}
.lxp-layout.ssl-layout #ssl-editor .ck-editor__editable::-webkit-scrollbar-thumb {
  background-color:rgba(245, 243, 239, 1);
  border-radius:10px;
}

</style>