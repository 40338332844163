<template>
  <main v-if="!isClassMain" class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="support-view-container">
        <div class="view-contents">
          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">신청정보</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
                <!--신청직원 정보-->
                <MobileApplicant />

                <div class="kb-form-field">
                  <InputCustomChainSelect
                      v-if="lrnCostDivs && lrnCostFlds"
                      v-model:value1="param.lrnCostDivSn"
                      v-model:value2="param.lrnCostFldSn"
                      v-model:toggle1="toggles.lrnCostDivs"
                      v-model:toggle2="toggles.lrnCostFlds"
                      title="학습구분 및 분야"
                      :options1="lrnCostDivs"
                      :options2="lrnCostFlds"
                      sequence-key1="lrnCostDivSn"
                      sequence-key2="lrnCostFldSn"
                      nameKey1="lrnCostDivNm"
                      nameKey2="lrnCostFldNm"
                      placeholder1="학습구분을 선택해주세요"
                      placeholder2="학습분야를 선택해주세요"
                      :disabled="isReadOnly"
                      :width="100"
                      :width2="100"
                      :width-px="false"
                      :width-px2="false"
                      @update:toggle1="closeToggles(toggles, 'lrnCostDivs')"
                      @update:toggle2="closeToggles(toggles, 'lrnCostFlds')"
                  />
                </div>

                <div class="content-item" v-if="param.lrnCostDivSn == '2' && param.lrnCostFldSn == '15'">
                  <div class="kb-form-fields">
                    <!-- kb-form-item:자격증명 -->
                    <div class="kb-form-item">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">자격증명 검색</span>
                        </label>
                      </div>
                      <div class="kb-form-column column-whole">
                        <input v-model="license.lcsnNm" type="text" class="kb-form-control" placeholder="자격증명 검색">
                      </div>
                    </div>
                  </div>
                  <div class="kb-btn-actions">
                    <button class="kb-btn-content-item" @click="searchMobModalFunc">
                      <span>자격증명 검색</span>
                    </button>
                  </div>
                </div>


                <!-- kb-form-field:학습방법 -->
                <div class="kb-form-field">
                  <InputCustomSelect
                      v-model:value1="param.lrnMthdCdDcd"
                      v-model:toggle1="toggles.lrnMthdCdDcd"
                      title="학습방법"
                      :options1="lrnMthdCdDcds"
                      placeholder1="학습방법을 선택해주세요"
                      sequence-key1="cd"
                      nameKey1="cdNm"
                      :disabled="isReadOnly"
                      :width="100"
                      :width-px="false"
                      @update:toggle1="closeToggles(toggles, 'lrnMthdCdDcd')"
                  />
                </div>
                <!-- kb-form-field:학원명 -->
                <div class="kb-form-field">
                  <LabelInputText v-model="param.acadNm" title="학원명" placeholder="학원명을 입력하세요" :disabled="isReadOnly"/>
                </div>
                <!-- kb-form-field:학원 홈페이지 -->
                <div class="kb-form-field">
                  <LabelInputText v-model="param.acadHmpg" title="학원 홈페이지" placeholder="학원 홈페이지 주소를 입력하세요" :disabled="isReadOnly"/>
                </div>
                <!-- kb-form-field:과정명 -->
                <div class="kb-form-field">
                  <LabelInputText
                      v-model="param.lrnSprtNm"
                      title="과정명"
                      placeholder="과정명을 입력하세요"
                      :disabled="isReadOnly"
                  />
                </div>
                <!-- kb-form-field:학습기간 -->
                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label>
                      <span class="kb-form-label-text">수강기간</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <Datepicker
                        v-model="param.lrnBgngDd"
                        v-model:toggle="toggles.lrnBgngDd"
                        :disabled="isReadOnly"
                    />
                  </div>
                  <div class="kb-form-row">
                    <Datepicker
                        v-model="param.lrnEndDd"
                        v-model:toggle="toggles.lrnEndDd"
                        :disabled="isReadOnly"
                    />
                  </div>
                </div>
                <!-- kb-form-field:수강증 -->
                <div class="kb-form-field">
                  <MobileUpload
                      v-model="lrnCertFiles"
                      title="수강증"
                      placeholder="수강자명,학원명,수강과정명,수강기간,수강료가 기입된 수강증을 등록하세요."
                      btn-title="수강증 등록하기"
                      :extensions="extensions"
                      :max-qty="10"
                      sequence-key="proofDataSn"
                      :disabled="isReadOnly"
                  />
                </div>
                <!-- kb-form-field:수강료 -->
                <div class="kb-form-field">
                  <LabelInputText v-model="param.schlFee" title="수강료" placeholder="수강료를 입력하세요" :is-number="true" :is-money="true" :disabled="isReadOnly"/>
                </div>
                <div class="kb-form-field">
                  <LabelInputText v-model="param.sprtExptAmt" title="지원금액" placeholder="수강료를 입력하세요" :is-number="true" :is-money="true" :disabled="true" />
                  <div class="kb-form-row">
                    <p class="kb-form-subtext">지원대상금액은 수강료의 80% / 교재비 제외</p>
                  </div>
                </div>
                <!-- //kb-form-field -->
              </div>
              <!-- //kb-form-fields -->
            </div>
          </section>
          <!-- content-section -->
          <MobilePayment
              v-if="isInit"
              v-model="paymentInfo"
              v-model:card-kinds-toggle="toggles.cardKinds"
              v-model:card-aprv-dt-toggle="toggles.cardAprvDt"
              v-model:files="receiptFiles"
              :disabled="isReadOnly"
              @update:modelValue="updatePaymentInfo(paymentInfo)"
          />

          <!-- content-section -->
          <section class="content-section">
            <header class="section-header header-divider">
              <h4 class="title">결과등록</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2">
                <div class="kb-form-field field-first">
                  <MobileUpload
                      v-model="acadCertFiles"
                      title="수료증 등록"
                      placeholder="수강한 교육과정의 수료증, 출석표, 진도율표 中 택1하여 등록해 주세요."
                      btn-title="수료증 등록하기"
                      :extensions="extensions"
                      :max-qty="10"
                      sequence-key="proofDataSn"
                  />
                </div>
              </div>
            </div>
          </section>

          <section class="content-section" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
            <header class="section-header header-divider">
              <h4 class="title">환입 및 추가지원금액</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2">
                <LabelInputText v-model="param.rtnAmt" title="환입금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.rtnYn=='Y'" />
              </div>
              <div class="kb-form-fields kb-form-fields-v2">
                <LabelInputText v-model="param.addAmt" title="추가지원금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.addAmtYn=='Y'" />
              </div>
            </div>
          </section>
          <!-- content-section -->
          <section class="content-section">
            <header class="section-header header-divider">
              <h4 class="title">지원정보</h4>
            </header>
            <div class="section-content">
              <div class="text-item-container">
                <!-- text-item -->
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">지원 한도액</h4>
                      <div class="item-meta fsize-m">
                        <strong class="text">{{ numberComma(supportAcademyYearlyLimit) }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- text-item -->
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">지원신청 누계액</h4>
                      <div class="item-meta fsize-m">
                        <strong class="text">{{ totalSprtExptAmt }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- text-item -->
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">지원 누계액</h4>
                      <div class="item-meta fsize-m">
                        <strong class="text">{{ numberComma(myTotalSprtAmt) }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //text-item -->
              </div>
            </div>
          </section>


          <section class="content-section" v-if="data == null">
            <UserAgreementMobile
                ref="usrAgrmntRef"
                v-model:user-agreement="userAgreement"
                @applyBtnEnable="applyBtnEnable"
            />
            <!--            <header class="section-header">-->
            <!--              <h4 class="title">증빙서류 제출시 유의사항</h4>-->
            <!--            </header>-->
            <!--            <div class="section-content">-->
            <!--              <div class="content-item">-->
            <!--                <div class="desc">-->
            <!--                  <p>1. 자기주도학습비는 직원의 자기계발 및 업무능력향상을 위해 은행재원으로 지원되고 있습니다. 본 제도의 취지에 맞지 않는 부당수령사례(환불/대리수강/증명서위변조/사은품 수령 등) 확인시 소속부점통보, 자기주도학습비 지원제한 및 각종 연수 참가 제한 조치되며 사안이 중대한 경우 인사조치 될 수 있습니다. 상기 내용을 숙지 하였음을 확인합니다.-->
            <!--                    <br><br>-->
            <!--                    2. 신청내용 검증을 위한 담당무서의 자료제출 및 개인정보 조회에 동의합니다.-->
            <!--                  </p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="content-item">-->
            <!--              <label for="chk_agree_03" class="item-agree">-->
            <!--                상기내용을 숙지하였으며 동의합니다.-->
            <!--                <div class="kb-form-check">-->
            <!--                  <input type="checkbox" v-model="userAgreement" class="kb-form-check-input" id="chk_agree_03" :value="true"/>-->
            <!--                </div>-->
            <!--              </label>-->
            <!--            </div>-->
          </section>
        </div>
        <MobileSupportButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
            type="academy"
        />
        <!--        <div class="page-buttons">-->
        <!--          <template v-if="data && (data.aplySttCdDcd === '2106003' || data.aplySttCdDcd === '2106010')">-->
        <!--            <button class="kb-btn kb-btn-outline" @click="closeModal">-->
        <!--              <span class="text">닫기</span>-->
        <!--            </button>-->
        <!--          </template>-->
        <!--          <template v-else>-->
        <!--            <button class="kb-btn kb-btn-primary" :disabled="applyBtnEnable(userAgreement)" @click="apply">-->
        <!--              <span v-if="data && (data.aplySttCdDcd === '2106001' || data.aplySttCdDcd === '2106006')" class="text">수정</span>-->
        <!--              <span v-else class="text">지원신청</span>-->
        <!--            </button>-->
        <!--            <button v-if="data && data.aplySttCdDcd === '2106001' " class="kb-btn kb-btn-outline" @click="cancel">-->
        <!--              <span class="text">지원신청 취소</span>-->
        <!--            </button>-->
        <!--          </template>-->
        <!--        </div>-->
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <LxpConfirm />

  <MobileEvlSearch
      v-if="isClassMain"
      v-model="isClassMain"
      v-model:lrn-cost-div-sn="param.lrnCostDivSn"
      v-model:selected-value="license"
      :keyword="license.lcsnNm"
      @update:modelValue="changeFld"
  />
</template>

<script>
import LxpConfirm from '@/components/common/LxpConfirm';
import Datepicker from '@/components/support/common/Datepicker';
import MobileApplicant from "@/components/support/mobile/MobileApplicant";
import LabelInputText from "@/components/support/common/LabelInputText";
import InputCustomChainSelect from "@/components/support/common/InputCustomChainSelect";
import InputCustomSelect from "@/components/support/common/InputCustomSelect";
import MobilePayment from "@/components/support/common/MobilePayment";
import MobileUpload from "@/components/support/common/MobileUpload";
import MobileSupportButton from "@/components/support/common/mobile/MobileSupportButton";
import UserAgreementMobile from "@/components/support/common/mobile/UserAgreementMobile";
import {supportClassFeeVupCommon} from "@/assets/js/modules/support/support-class-fee-vup-common";
import MobileEvlSearch from "@/components/support/mobile/MobileEvlSearch.vue";

export default {
  name: "SupportClassFee202309MobileModal",
  components: {
    MobileEvlSearch,
    MobileSupportButton,
    MobileUpload,
    InputCustomSelect,
    InputCustomChainSelect,
    MobilePayment,
    LabelInputText,
    MobileApplicant,
    Datepicker,
    LxpConfirm,
    UserAgreementMobile,
  },
  props: {
    modelValue: Boolean,
    data: {
      type: Object,
      required: false,
      default: null
    },
    reList : Function
  },
  setup: supportClassFeeVupCommon
};
</script>
