export const writeExitPlane = '페이지를 나가면 작성중이던 내용은 삭제됩니다.\n원치 않으실 경우 [취소] 후 [임시저장]을 해주세요.';
export const writeExit = '페이지를 나가면 작성중이던 내용은 삭제됩니다.<br>원치 않으실 경우 [취소] 후 [임시저장]을 해주세요.';

export const writeInsertNote = '등록한 노트는 삭제할 수 없습니다.<br>노트를 등록하시겠습니까?';
// export const writeUpdateNote = '노트를 수정하시겠습니까?';

export const writeDayLimit = '금일 노트 작성을 완료하였습니다.<br>(1일 1노트만 등록 가능합니다.)';
export const writeTotalLimit = (limit) => {
    return `연간 작성 노트 수를<br>모두 완료하였습니다.<br>(연간${limit}개 등록가능)`;
}

export const writeDeleteConfirm = '해당 노트를 삭제 하시겠습니까?';


export const viewCommentEditConfirm = (edit) => {
    return `댓글을 ${edit}하시겠습니까?`;
}

export const viewCommentUpdate = '댓글이 수정되었습니다.';
export const viewCommentDelete = '댓글이 삭제되었습니다.';

export const bookOpen = '7.1(월) 17:00 Open 예정';

export const popupBlock = '팝업이 차단되어 있습니다.\n팝업 차단을 해제 후 다시 시도해주세요.\n[설정 -> 기본인터넷앱(Safari 등) -> 팝업 차단 해제]'
export const ssoFail = '로그인 정보를 가져올 수 없습니다.\n모든 쿠키 차단을 해제 후 다시 시도해주세요.\n[설정 -> 기본인터넷앱(Safari 등) -> 모든 쿠키 차단 해제]'

// export const limitSSL = '7.1(금) 24시 이후 학습노트<br>등록이 불가능합니다.';
// export const limitTempSSL = '7.1(금) 24시 이후 학습노트<br>임시저장이 불가능합니다.';

// export const limitSSL = 'SSL 하반기 학습기간이 종료되었습니다.';
// export const limitTempSSL = 'SSL 하반기 학습기간이 종료되었습니다.';

export const limitFirstSSL = 'SSL 상반기 학습기간이 종료되었습니다.';
// export const limitFirstTempSSL = 'SSL 상반기 학습기간이 종료되었습니다.';
export const limitSecondSSL = 'SSL 하반기 학습기간이 종료되었습니다.';
// export const limitSecondTempSSL = 'SSL 하반기 학습기간이 종료되었습니다.';

export const limitSSL = 'SSL 학습기간이 종료되었습니다.';